import {Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import {Passation,Tag} from '../passation';


@Component({
  selector: 'app-user-passations',
  templateUrl: './user-passations.component.html',
  styleUrls: ['./user-passations.component.css']
})
export class UserPassationsComponent implements OnInit {

  userPassations?:[];
  passationPage:Number=1;
  passationSortOption:Number = 1;
  passationFilter:string="";
  orgName:String="";

  constructor(private userService:UserService,) { }

  ngOnInit(): void {
    this.orgName = this.userService.getUserDetails().organizationName;
    this.userService.getPassations().subscribe(answer =>{
       this.userPassations = answer["passations"];
    });

  }

  formatTags(tags:Tag[]){
    let res ="";
    if (tags.length>0){
      let i =0;
      while(i<tags.length-1){
        res += tags[i].name +", "
        i++;
      }
      res += tags[i].name;
    }
    return res;
  }

}


