import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: {'class': 'ui-body-content d-flex flex-grow-1 flex-column'}
})
export class LoginComponent implements OnInit {

  constructor(private router:Router,private userService:UserService) { }

  connectId:string;
  connectPwd:string;
  connectionErrorText :string;
  requestEmail:string;

  ngOnInit(): void {
  }
  login(identifiant,pwd){
    this.connectionErrorText = "";
    this.userService.login(identifiant,pwd).subscribe(
    answer => {
            this.router.navigateByUrl('/');
            },
    error => {
        console.log(error);
        if (error == 401){
         this.connectionErrorText = "Identifiants invalides.";
        }
        else if (error == 0){
          this.connectionErrorText = "Le serveur ne répond pas.";
        }
        else{
          this.connectionErrorText ="Erreur : code "+ error;
        }
    });
  }
   toggleShowPassword():void {
    var x:any = document.getElementById("connectPwd");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
}
