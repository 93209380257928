<app-menu class="row"></app-menu>
<div *ngIf="currentReport == null" >
  <!-- Generation  -->

  <div class="d-flex row reporting-tab-div ">
    <div class="d-flex col-6">
      <button [disabled]="isExport==false" class="flex-grow-1 reporting-tab-btn" (click)="isExport=false" >Rapports</button>
    </div>
    <div class="d-flex col-6">
      <button  [disabled]="isExport==true " class="flex-grow-1 reporting-tab-btn" (click)="isExport=true" >Exports</button>
    </div>
  </div>
  <div class="row">
    <h1 *ngIf="isExport==false" class="offset-2 col-8 text-center">Générer un rapport</h1>
    <h1 *ngIf="isExport==true" class="offset-2 col-8 text-center">Exporter les données</h1>
  </div>
  <div *ngIf="isExport==false" class="row">
    <h2 class="offset-md-1 col-10 ">Type de rapport</h2>
  </div>
  <div *ngIf="isExport==false" class="row ">
    <div class="reporting-select-div offset-1  col-md-4 col-10">
      <select id="passationCode" [(ngModel)]="reportType" id="reportingTypeSelect">
        <option [value]="0">Qualité de vie perçue à temps donné</option>
        <option [value]="1">Qualité de vie perçue à l’entrée</option>
        <option [value]="2">Evolution de la qualité de vie</option>
      </select>
    </div>
    <div class="offset-1 col-md-5 col-10 reporting-select-help-text">
      {{infoTexts[reportType]}}
    </div>
  </div>
  <div  *ngIf ="canSeeMultipleOrganisations">
    <div class="row ">
      <h2 class="offset-md-1 col-10">Organisations</h2>
    </div>
    <div class="row admin-edit-passation-modal-line">
      <div class="offset-md-1 col-md-10">
        <ng-select  class="ui-ng-select" id = "organisationsSelected" name = "organisationSelected"
                    [items]="organizations"
                    bindLabel="name"
                    (change)="filterTagList()"
                    [(ngModel)]="selectedOrganizations"
                    [addTag]="false"
                    [multiple] ="true">
        </ng-select>
        <button class="btn btn-primary" type="button"  (click)="onSelectAllOrg()">Selectionner toutes</button>
      </div>
    </div>
  </div>
  <div class="row">
    <h2 class="offset-md-1 col-10">Bornes temporelles</h2>
  </div>

  <div class="row">
    <div class="offset-md-1 col-md-5 col-12d-flex justify-content-start">
      <label for="dateCheckbox">Aucune borne temporelle</label>
      <input [(ngModel)]="noDate" id ="dateCheckbox" type="checkbox">

    </div>
  </div>

  <div class="row ">
    <div class="offset-md-1 col-md-5 col-12 d-flex justify-content-start ">
      <label  [ngClass]="{'disabled':noDate==true}" class="ui-label-margin"> Date de début</label>
      <input [disabled]="noDate==true" type="date" name="startDate" #startDate="ngModel" required
             [ngModel]="startDateValue | date:'yyyy-MM-dd'"
             (ngModelChange)="startDateValue = $event"
      />
    </div>
    <div class=" col-md-5 d-flex col-12 justify-content-start ">
      <label [ngClass]="{'disabled':noDate==true}" class="ui-label-margin"> Date de fin</label>
      <input [disabled]="noDate==true" type="date" name="stopDate" #stopDate="ngModel" required
             [ngModel]="stopDateValue | date:'yyyy-MM-dd'"
             (ngModelChange)="stopDateValue = $event"
      />
    </div>
  </div>


  <div class="row ">
    <h2 class="offset-md-1 col-md-10">Filtre par tags</h2>
  </div>
  <div class="row">
    <i class="row offset-md-1 col-10">Seules les passations présentant au moins l'un des tags indiqués seront retenues</i>
  </div>
  <div *ngIf="canSeeMultipleOrganisations" class="row">
    <i class="row offset-md-1 col-10">Seuls les tags présents dans les organisations selectionnés sont affichés</i>
  </div>
  <div class="row admin-edit-passation-modal-line">
    <div class="offset-md-1 col-10">
      <ng-select  class="ui-ng-select" id = "passationTags" name = "passationTags"
                  [items]="filteredTagList"
                  bindLabel="name"
                  [(ngModel)]="reportTags"
                  [addTag]="false"
                  [multiple] ="true">
      </ng-select>
    </div>
  </div>
  <div *ngIf="canSeeMultipleOrganisations&&selectedOrganizations.length ==0" class="d-flex justify-content-center" style="color:red">
    <i>Selectionnez au moins une organisation</i>
  </div>
  <div *ngIf="isExport==false" class="d-flex justify-content-center">
    <button [disabled]="canSeeMultipleOrganisations&&selectedOrganizations.length ==0" (click)="generateReport()" class="btn btn-primary">Générer un rapport</button>
  </div>
  <div *ngIf="isExport==true" class="d-flex justify-content-center">
    <button [disabled]="canSeeMultipleOrganisations&&selectedOrganizations.length ==0" (click)="generateExport()" class="btn btn-primary">Télécharger l'export</button>
  </div>
</div>



<div class="d-flex justify-content-around hidePrint reporting-return-div" *ngIf="currentReport != null">
  <button (click)="resetReport()" class="btn btn-primary"> Générer un autre rapport</button>
</div>

<div class="d-flex align-items-center flex-column" *ngIf="currentReport != null &&
((currentReport['reportType']!=2 && currentReport['passationReport']['passationCount']==0) ||
 (currentReport['reportType']==2 && currentReport['evolutionReport']['pairedPassationNumber']==0))">
  Aucune passation ne correspond aux dates et tags selectionnées.
</div>


<!-- ----------------------- RAPPORT 1 -------------------------- -->


<div
  *ngIf="currentReport != null && currentReport['reportType']==0 && currentReport['passationReport']['passationCount']>0">
  <div class="reporting-wrapper">
    <!-- header rapport -->
    <div class="d-flex justify-content-between align-items-center reporting-header">
      <div class="d-none d-md-block d-print">
        <h2>Rapport de qualité de vie perçue à temps donné</h2>
        <span *ngIf="currentReport.startDate != null && currentReport.stopDate == null"> Passations effectuées à partir du {{currentReport.startDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate == null && currentReport.stopDate != null"> Passations effectuées jusqu'au  {{currentReport.stopDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate != null && currentReport.stopDate != null"> Passations effectuées entre le {{currentReport.startDate| date:"dd/MM/yyyy"}} et le {{currentReport.stopDate| date:"dd/MM/yyyy"}}</span>

        <div *ngIf="currentReport.tags.length>0">
          Limité aux passations présentant les tags suivant : <strong
          *ngFor="let tag of currentReport.tags">{{tag}} </strong>
        </div>
      </div>
      <div class="d-none d-md-block d-print">
        <img src="assets/logo_atev.png" class="result-logo-atev-img">
      </div>
    </div>

    <div class="result-passations-wrapper">
      <div class="d-flex flex-column reporting-width ">
        <div class="reporting-padding row">
          <div class="col-6 reporting-text-section">
            <div>Nombre de passations sur la période choisie :
              <strong>{{currentReport.passationReport.passationCount}}</strong> passation<span *ngIf="currentReport.passationReport.passationCount>1">s</span>
            </div>
            <div>Nombre d'usagers différents : <strong>{{currentReport.passationReport.separatePatientCount}}
              <span *ngIf="currentReport.passationReport.separatePatientCount==1">usager
              distinct</span>
              <span *ngIf="currentReport.passationReport.separatePatientCount>1">usagers
              distincts</span>
            </strong>
            </div>
            <div class="resume-subinfo-div">dont <strong>{{currentReport.passationReport.menCount}}</strong> homme(s),
              <strong>{{currentReport.passationReport.womenCount}}</strong> femme(s) et <strong>{{currentReport.passationReport.otherCount}}</strong>
              non
              binaire(s)
            </div>
          </div>
          <div class="col-6 reporting-text-section">
            <div>Moyenne d'âge des usagers : <strong>{{currentReport.passationReport.averageAge.toFixed(1)}}
              ans</strong>
            </div>
            <div>
              Âge minimum : <strong>{{currentReport.passationReport.minAge}} ans </strong>
            </div>
            <div>
              Âge maximum : <strong>{{currentReport.passationReport.maxAge}}
              ans</strong>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Statistiques générales des piliers</div>
    </div>
    <div class="reporting-table-section row ">
      <div class="col-6 reporting-padding results-pillar-table-div">
        <div>
          Nombre moyen de piliers cités : <strong>{{currentReport.passationReport.averagePillarCount.toFixed(1)}}
          piliers
          cités</strong>
        </div>
        <table>
          <tr>
            <th>

            </th>
            <th>
              1 pilier
            </th>
            <th>
              2 piliers
            </th>
            <th>
              3 piliers
            </th>
            <th>
              4 piliers
            </th>
          </tr>
          <tr>
            <th>
              % de passations citant
            </th>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[0]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[1]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[2]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[3]*100).toFixed(1)}}%
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6 passation-pillar-count graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('passation-pillar-count-graph')">enregistrer
        </button>
      </div>
    </div>

    <div class="reporting-section d-flex justify- 500content-center align-items-center reporting-padding">
      <div>Statistiques par pilier</div>
    </div>
    <div class="row">
      <div class="col-10 offset-md-1 reporting-padding reporting-table-section">
        <table>
          <tr>
            <th>
              Pilier
            </th>
            <td class="bg-pillar-0">
              Sécurité et <br>cadre de vie
            </td>
            <td class="bg-pillar-2">
              Vie affective et <br> relation aux autres
            </td>
            <td class="bg-pillar-1">
              Santé et pratiques <br> de santé
            </td>
            <td class="bg-pillar-3">
              Ressources personnelles <br> et loisirs
            </td>
          </tr>
          <tr>
            <th>
              Cité par
            </th>
            <td>
              {{(currentReport.passationReport.pillarStatistics['SECURITY'].pillarProportion*100).toFixed(1)}} %
            </td>
            <td>
              {{(currentReport.passationReport.pillarStatistics['RELATION'].pillarProportion*100).toFixed(1)}} %
            </td>
            <td>
              {{(currentReport.passationReport.pillarStatistics['HEALTH'].pillarProportion*100).toFixed(1)}} %
            </td>
            <td>
              {{(currentReport.passationReport.pillarStatistics['HOBBIES'].pillarProportion*100).toFixed(1)}} %
            </td>
          </tr>
          <tr>
            <th>
              Part moyenne
            </th>
            <td>
              {{currentReport.passationReport.pillarStatistics['SECURITY'].averagePart.toFixed(1)}} %
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['RELATION'].averagePart.toFixed(1)}} %
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HEALTH'].averagePart.toFixed(1)}} %
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].averagePart.toFixed(1)}} %
            </td>
          </tr>
          <tr>
            <th>
              Part min/max
            </th>
            <td>
              {{currentReport.passationReport.pillarStatistics['SECURITY'].minimumPart}} -
              {{currentReport.passationReport.pillarStatistics['SECURITY'].maximumPart}}%
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['RELATION'].minimumPart}} -
              {{currentReport.passationReport.pillarStatistics['RELATION'].maximumPart}}%

            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HEALTH'].minimumPart}} -
              {{currentReport.passationReport.pillarStatistics['HEALTH'].maximumPart}}%
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].minimumPart}} -
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].maximumPart}}%
            </td>
          </tr>
          <tr>
            <th>
              Note moyenne
            </th>
            <td>
              {{currentReport.passationReport.pillarStatistics['SECURITY'].averageNote.toFixed(1)}}
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['RELATION'].averageNote.toFixed(1)}}
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HEALTH'].averageNote.toFixed(1)}}
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].averageNote.toFixed(1)}}
            </td>
          </tr>
          <tr>
            <th>
              Note min/max
            </th>
            <td>
              {{currentReport.passationReport.pillarStatistics['SECURITY'].minimumNote}} -
              {{currentReport.passationReport.pillarStatistics['SECURITY'].maximumNote}}
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['RELATION'].minimumNote}} -
              {{currentReport.passationReport.pillarStatistics['RELATION'].maximumNote}}

            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HEALTH'].minimumNote}} -
              {{currentReport.passationReport.pillarStatistics['HEALTH'].maximumNote}}
            </td>
            <td>
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].minimumNote}} -
              {{currentReport.passationReport.pillarStatistics['HOBBIES'].maximumNote}}
            </td>
          </tr>
        </table>
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="onExportPassationTable('passation-pillar-presence-graph')">enregistrer
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-6  pillar-proportion graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary" (click)="saveGraph('pillar-proportion-graph')">
          enregistrer
        </button>
      </div>
      <div class="col-6 passation-pillar-presence graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('passation-pillar-presence-graph')">enregistrer
        </button>
      </div>
    </div>


    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Qualité de vie</div>
    </div>
    <div class="result-passations-wrapper">
      <div class="reporting-padding">
        <div>
          Qualité de vie moyenne perçue : <strong>
          {{currentReport.passationReport.averageScore.toFixed(1)}}/100 </strong>
        </div>
        <div>
          Qualité de vie minimum : <strong>{{currentReport.passationReport.minScore}}/100</strong>
        </div>
        <div>
          Qualité de vie maximum : <strong>{{currentReport.passationReport.maxScore}}/100</strong>
        </div>
      </div>
    </div>


  </div>

  <div class="hidePrint d-flex justify-content-around">
    <button (click)="onPrintClicked()" class="btn btn-primary">
      Imprimer
    </button>
    <button (click)="onExportClicked(currentReport)" class="btn btn-primary">
      Export des données
    </button>
  </div>

</div>

<!-- ----------------------- RAPPORT 2 -------------------------- -->

<div
  *ngIf="currentReport != null && currentReport['reportType']==1 && currentReport['passationReport']['passationCount']>0">
  <div class="reporting-wrapper">
    <!-- header rapport -->
    <div class="d-flex justify-content-between align-items-center reporting-header">
      <div class="d-none d-md-block d-print">
        <h2>Rapport de qualité de vie perçue à l’entrée dans l’établissement</h2>
        <span *ngIf="currentReport.startDate != null && currentReport.stopDate == null"> Usagers présents à partir du {{currentReport.startDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate == null && currentReport.stopDate != null"> Usagers présents jusqu'au  {{currentReport.stopDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate != null && currentReport.stopDate != null"> Usagers présents entre le {{currentReport.startDate| date:"dd/MM/yyyy"}} et le  {{currentReport.stopDate| date:"dd/MM/yyyy"}}</span>

        <span *ngIf="currentReport.startDate != null" ></span>
        <div *ngIf="currentReport.tags.length>0">
          Limité aux passations présentant les tags suivant : <strong
          *ngFor="let tag of currentReport.tags">{{tag}} </strong>
        </div>
      </div>


      <div class="d-none d-md-block d-print">
        <img src="assets/logo_atev.png" class="result-logo-atev-img">
      </div>
    </div>

    <div class="result-passations-wrapper">
      <div class="d-flex flex-column reporting-width ">
        <div class="reporting-padding row">
          <div class=" reporting-text-section">
            <div>Nombre d'usagers selectionnés sur la période choisie :
              <strong>{{currentReport.passationReport.passationCount}}</strong>
              <span *ngIf="currentReport.passationReport.passationCount==1"> usager
              distinct</span>
              <span *ngIf="currentReport.passationReport.passationCount>1"> usagers
              distincts</span>

            </div>
            <!--  <div>Nombre d'usagers différents : <strong>{{currentReport.passationReport.separatePatientCount}} usagers
                distincts</strong>
              </div>-->
            <div class="resume-subinfo-div">dont <strong>{{currentReport.passationReport.menCount}}</strong> homme(s),
              <strong>{{currentReport.passationReport.womenCount}}</strong> femme(s) et <strong>{{currentReport.passationReport.otherCount}}</strong>
              non
              binaire(s)
            </div>
            <div>Moyenne d'âge des usagers différents : <strong>{{currentReport.passationReport.averageAge.toFixed(1)}}
              ans</strong>
            </div>
            <div>
              Âge minimum : <strong>{{currentReport.passationReport.minAge}} ans </strong>
            </div>
            <div>
              Âge maximum : <strong>{{currentReport.passationReport.maxAge}}
              ans</strong>
            </div>
          </div>
          <!-- <div class ="col-6">
             <canvas id="canvas" height="450" width="600"></canvas>

             <img id="url" />
           </div>-->
        </div>
      </div>
    </div>
    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Statistiques générales des piliers</div>
    </div>
    <div class="reporting-table-section row ">
      <div class="col-6 reporting-padding results-pillar-table-div">
        <div>
          Nombre moyen de piliers cités : <strong>{{currentReport.passationReport.averagePillarCount.toFixed(1)}}
          piliers
          cités</strong>
        </div>
        <table>
          <tr>
            <th>
            </th>
            <th>
              1 pilier
            </th>
            <th>
              2 piliers
            </th>
            <th>
              3 piliers
            </th>
            <th>
              4 piliers
            </th>
          </tr>
          <tr>
            <th>
              % d'usagers citant
            </th>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[0]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[1]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[2]*100).toFixed(1)}}%
            </td>
            <td>
              {{(currentReport.passationReport.pillarPresentCount[3]*100).toFixed(1)}}%
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6 passation-pillar-count graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('passation-pillar-count-graph')">enregistrer
        </button>
      </div>
    </div>

    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Statistiques par pilier</div>
    </div>

    <div class="reporting-padding reporting-table-section">
      <table>
        <tr>
          <th>
            Pilier
          </th>
          <td class="bg-pillar-0">
            Sécurité et <br>cadre de vie
          </td>
          <td class="bg-pillar-2">
            Vie affective et <br> relation aux autres
          </td>
          <td class="bg-pillar-1">
            Santé et pratiques <br> de santé
          </td>
          <td class="bg-pillar-3">
            Ressources personnelles <br> et loisirs
          </td>
        </tr>
        <tr>
          <th>
            Cité par
          </th>
          <td>
            {{(currentReport.passationReport.pillarStatistics['SECURITY'].pillarProportion*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.passationReport.pillarStatistics['RELATION'].pillarProportion*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.passationReport.pillarStatistics['HEALTH'].pillarProportion*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.passationReport.pillarStatistics['HOBBIES'].pillarProportion*100).toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th>
            Part moyenne
          </th>
          <td>
            {{currentReport.passationReport.pillarStatistics['SECURITY'].averagePart.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['RELATION'].averagePart.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HEALTH'].averagePart.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].averagePart.toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th>
            Part min/max
          </th>
          <td>
            {{currentReport.passationReport.pillarStatistics['SECURITY'].minimumPart}} -
            {{currentReport.passationReport.pillarStatistics['SECURITY'].maximumPart}}%
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['RELATION'].minimumPart}} -
            {{currentReport.passationReport.pillarStatistics['RELATION'].maximumPart}}%

          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HEALTH'].minimumPart}} -
            {{currentReport.passationReport.pillarStatistics['HEALTH'].maximumPart}}%
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].minimumPart}} -
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].maximumPart}}%
          </td>
        </tr>
        <tr>
          <th>
            Note moyenne
          </th>
          <td>
            {{currentReport.passationReport.pillarStatistics['SECURITY'].averageNote.toFixed(1)}}
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['RELATION'].averageNote.toFixed(1)}}
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HEALTH'].averageNote.toFixed(1)}}
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].averageNote.toFixed(1)}}
          </td>
        </tr>
        <tr>
          <th>
            Note min/max
          </th>
          <td>
            {{currentReport.passationReport.pillarStatistics['SECURITY'].minimumNote}} -
            {{currentReport.passationReport.pillarStatistics['SECURITY'].maximumNote}}
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['RELATION'].minimumNote}} -
            {{currentReport.passationReport.pillarStatistics['RELATION'].maximumNote}}

          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HEALTH'].minimumNote}} -
            {{currentReport.passationReport.pillarStatistics['HEALTH'].maximumNote}}
          </td>
          <td>
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].minimumNote}} -
            {{currentReport.passationReport.pillarStatistics['HOBBIES'].maximumNote}}
          </td>
        </tr>
      </table>
      <button class="reporting-save-btn hidePrint btn btn-primary"
              (click)="onExportPassationTable('passation-pillar-presence-graph')">enregistrer
      </button>
    </div>
    <div class="row">
      <div class="col-6  pillar-proportion graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary" (click)="saveGraph('pillar-proportion-graph')">
          enregistrer
        </button>
      </div>
      <div class="col-6 passation-pillar-presence graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('passation-pillar-presence-graph')">enregistrer
        </button>
      </div>
    </div>

    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Qualité de vie</div>
    </div>
    <div class="result-passations-wrapper">
      <div class="reporting-padding">
        <div>
          Qualité de vie moyenne perçue : <strong>
          {{currentReport.passationReport.averageScore.toFixed(1)}}/100 </strong>
        </div>
        <div>
          Qualité de vie minimum : <strong>{{currentReport.passationReport.minScore}}/100</strong>
        </div>
        <div>
          Qualité de vie maximum : <strong>{{currentReport.passationReport.maxScore}}/100</strong>
        </div>
      </div>
    </div>


  </div>

  <div class="hidePrint d-flex justify-content-around">
    <button (click)="onPrintClicked()" class="btn btn-primary">
      Imprimer
    </button>
    <button (click)="onExportClicked(currentReport)" class="btn btn-primary">
      Export des données
    </button>
  </div>

</div>

<!-- ----------------------- RAPPORT 3 -------------------------- -->

<div
  *ngIf="currentReport != null && currentReport['reportType']==2 && currentReport['evolutionReport']['pairedPassationNumber']>0">
  <div class="reporting-wrapper">
    <!-- header rapport -->
    <div class="d-flex justify-content-between align-items-center reporting-header">
      <div class="d-none d-md-block d-print">
        <h2>Rapport d'évolution de la qualité de vie perçue des usagers au fil de l’accompagnement</h2>

        <span *ngIf="currentReport.startDate != null && currentReport.stopDate == null"> Usagers ayant plusieurs passations à partir du {{currentReport.startDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate == null && currentReport.stopDate != null"> Usagers ayant plusieurs passations jusqu'au {{currentReport.stopDate| date:"dd/MM/yyyy"}}</span>
        <span *ngIf="currentReport.startDate != null && currentReport.stopDate != null"> Usagers ayant plusieurs passations entre le {{currentReport.startDate| date:"dd/MM/yyyy"}} et le {{currentReport.stopDate| date:"dd/MM/yyyy"}} </span>

        <div *ngIf="currentReport.tags.length>0">
          Limité aux passations présentant les tags suivant : <strong
          *ngFor="let tag of currentReport.tags">{{tag}} </strong>
        </div>
      </div>
      <div class="d-none d-md-block d-print">
        <img src="assets/logo_atev.png" class="result-logo-atev-img">
      </div>
    </div>

    <div class="result-passations-wrapper">
      <div class="d-flex flex-column reporting-width ">
        <div class="reporting-padding row">
          <div class=" reporting-text-section">
            <div>Nombre d'usagers selectionnés sur la période choisie :
              <strong>{{currentReport.evolutionReport.pairedPassationNumber}}</strong> paire<span *ngIf="currentReport.evolutionReport.pairedPassationNumber>1">s</span> de passations
            </div>
          </div>
        </div>
        <div class="reporting-padding row">
          <div class="results-pillar-table-div col-6">
            <div class="reporting-table-section">
              <div class="reporting-padding">
                <div>
                  Nombre moyen de piliers cités par personne : <br>
                  Début : <strong>{{currentReport.evolutionReport.averagePillarCountT1.toFixed(1)}}
                  piliers</strong> <br>
                  Fin : <strong>{{currentReport.evolutionReport.averagePillarCountT2.toFixed(1)}}
                  piliers</strong><br>
                </div>
                <table>
                  <tr>
                    <th>

                    </th>
                    <th>
                      1 pilier
                    </th>
                    <th>
                      2 piliers
                    </th>
                    <th>
                      3 piliers
                    </th>
                    <th>
                      4 piliers
                    </th>
                  </tr>
                  <tr>
                    <th>
                      Première passation
                    </th>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT1[0]*100).toFixed(1)}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT1[1]).toFixed(1)*100}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT1[2]).toFixed(1)*100}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT1[3]).toFixed(1)*100}}%
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Dernière passation
                    </th>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT2[0]*100).toFixed(1)}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT2[1]*100).toFixed(1)}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT2[2]*100).toFixed(1)}}%
                    </td>
                    <td>
                      {{(currentReport.evolutionReport.pillarPresentCountT2[3]*100).toFixed(1)}}%
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            class="col-6 d-flex flex-column align-items-center justify-content-center evolution-pillar-count graph-div">
            <button class="reporting-save-btn hidePrint btn btn-primary"
                    (click)="saveGraph('evolution-pillar-count-graph')">enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Statistiques comparatives par piliers</div>
    </div>
    <div class="reporting-padding reporting-table-section">
      <table>
        <!--header-->
        <tr>
          <th>
            Pilier
          </th>
          <td class="bg-pillar-0">
            Sécurité et <br>cadre de vie
          </td>
          <td class="bg-pillar-2">
            Vie affective et <br> relation aux autres
          </td>
          <td class="bg-pillar-1">
            Santé et pratiques <br> de santé
          </td>
          <td class="bg-pillar-3"  >
            Ressources personnelles <br> et loisirs
          </td>
        </tr>
        <!--présence moyenne-->
        <tr>
          <th>
            T1 :Cité par :
          </th>

          <td>
            {{(currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['RELATION'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT1*100).toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th>
            T2 :Cité par :
          </th>

          <td>
            {{(currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT2*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['RELATION'].pillarProportionT2*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT2*100).toFixed(1)}} %
          </td>
          <td>
            {{(currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT2*100).toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th class="reporting-comparaison-div">
            <i>différentiel</i>
          </th>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT2>currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT1">+</span>
            {{(currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT2*100-
            currentReport.evolutionReport.pillars['SECURITY'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['RELATION'].pillarProportionT2>currentReport.evolutionReport.pillars['RELATION'].pillarProportionT1">+</span>
            {{(currentReport.evolutionReport.pillars['RELATION'].pillarProportionT2*100-
            currentReport.evolutionReport.pillars['RELATION'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT2>currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT1">+</span>
            {{(currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT2*100-
            currentReport.evolutionReport.pillars['HEALTH'].pillarProportionT1*100).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT2>currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT1">+</span>
            {{(currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT2*100-
            currentReport.evolutionReport.pillars['HOBBIES'].pillarProportionT1*100).toFixed(1)}} %
          </td>
        </tr>
        <!--part moyenne-->
        <tr>
          <th>
            T1 : part moyenne :
          </th>

          <td>
            {{currentReport.evolutionReport.pillars['SECURITY'].averagePartT1.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['RELATION'].averagePartT1.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HEALTH'].averagePartT1.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HOBBIES'].averagePartT1.toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th>
            T2 :part moyenne :
          </th>

          <td>
            {{currentReport.evolutionReport.pillars['SECURITY'].averagePartT2.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['RELATION'].averagePartT2.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HEALTH'].averagePartT2.toFixed(1)}} %
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HOBBIES'].averagePartT2.toFixed(1)}} %
          </td>
        </tr>
        <tr>
          <th class="reporting-comparaison-div">
            <i>différentiel</i>
          </th>

          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['SECURITY'].averagePartT2>currentReport.evolutionReport.pillars['SECURITY'].averagePartT1">+</span>
            {{(currentReport.evolutionReport.pillars['SECURITY'].averagePartT2-
            currentReport.evolutionReport.pillars['SECURITY'].averagePartT1).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['RELATION'].averagePartT2>currentReport.evolutionReport.pillars['RELATION'].averagePartT1">+</span>
            {{(currentReport.evolutionReport.pillars['RELATION'].averagePartT2-
            currentReport.evolutionReport.pillars['RELATION'].averagePartT1).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HEALTH'].averagePartT2>currentReport.evolutionReport.pillars['HEALTH'].averagePartT1">+</span>
            {{(currentReport.evolutionReport.pillars['HEALTH'].averagePartT2-
            currentReport.evolutionReport.pillars['HEALTH'].averagePartT1).toFixed(1)}} %
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HOBBIES'].averagePartT2>currentReport.evolutionReport.pillars['HOBBIES'].averagePartT1">+</span>
            {{(currentReport.evolutionReport.pillars['HOBBIES'].averagePartT2-
            currentReport.evolutionReport.pillars['HOBBIES'].averagePartT1).toFixed(1)}} %
          </td>
        </tr>
        <!--note moyenne-->
        <tr>
          <th>
            T1 : note moyenne :
          </th>

          <td>
            {{currentReport.evolutionReport.pillars['SECURITY'].averageNoteT1.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['RELATION'].averageNoteT1.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HEALTH'].averageNoteT1.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT1.toFixed(1)}}
          </td>
        </tr>
        <tr>
          <th>
            T2 :Note moyenne :
          </th>

          <td>
            {{currentReport.evolutionReport.pillars['SECURITY'].averageNoteT2.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['RELATION'].averageNoteT2.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HEALTH'].averageNoteT2.toFixed(1)}}
          </td>
          <td>
            {{currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT2.toFixed(1)}}
          </td>
        </tr>
        <tr>
          <th class="reporting-comparaison-div">
            <i>différentiel</i>
          </th>

          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['SECURITY'].averageNoteT2>currentReport.evolutionReport.pillars['SECURITY'].averageNoteT1">+</span>
            {{(currentReport.evolutionReport.pillars['SECURITY'].averageNoteT2-
            currentReport.evolutionReport.pillars['SECURITY'].averageNoteT1).toFixed(1)}}
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['RELATION'].averageNoteT2>currentReport.evolutionReport.pillars['RELATION'].averageNoteT1">+</span>
            {{(currentReport.evolutionReport.pillars['RELATION'].averageNoteT2-
            currentReport.evolutionReport.pillars['RELATION'].averageNoteT1).toFixed(1)}}
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HEALTH'].averageNoteT2>currentReport.evolutionReport.pillars['HEALTH'].averageNoteT1">+</span>
            {{(currentReport.evolutionReport.pillars['HEALTH'].averageNoteT2-
            currentReport.evolutionReport.pillars['HEALTH'].averageNoteT1).toFixed(1)}}
          </td>
          <td class="reporting-comparaison-div">
            <span
              *ngIf="currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT2>currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT1">+</span>
            {{(currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT2-
            currentReport.evolutionReport.pillars['HOBBIES'].averageNoteT1).toFixed(1)}}
          </td>
        </tr>
      </table>
      <button class="reporting-save-btn hidePrint btn btn-primary"
              (click)="onExportEvolutionTable('passation-pillar-presence-graph')">enregistrer
      </button>
    </div>
    <div class="row">
      <div class="col-6 evolution-pillar-presence graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('evolution-pillar-presence-graph')">enregistrer
        </button>
      </div>
      <div class="col-6 evolution-pillar-parts graph-div">
        <button class="reporting-save-btn hidePrint btn btn-primary"
                (click)="saveGraph('evolution-pillar-parts-graph')">enregistrer
        </button>
      </div>
    </div>
    <div class="reporting-section d-flex justify-content-center align-items-center reporting-padding">
      <div>Qualité de vie</div>
    </div>
    <div class="result-passations-wrapper">
      <div class="reporting-padding">
        <div>
          Qualité de vie moyenne perçue au début : <strong>
          {{currentReport.evolutionReport.averageScoreT1.toFixed(1)}}/100 </strong>
        </div>
        <div>
          Qualité de vie moyenne perçue à la fin :
          <strong>{{currentReport.evolutionReport.averageScoreT2.toFixed(1)}}/100</strong>
        </div>
        <div>
          Différentiel : <strong>
          <span
            *ngIf="currentReport.evolutionReport.averageScoreT2>currentReport.evolutionReport.averageScoreT1">+</span>
          {{(currentReport.evolutionReport.averageScoreT2-currentReport.evolutionReport.averageScoreT1).toFixed(1)}}</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="hidePrint d-flex justify-content-around">
    <button (click)="onPrintClicked()" class="btn btn-primary">
      Imprimer
    </button>
    <button (click)="onExportClicked(currentReport)" class="btn btn-primary">
      Export des données
    </button>
  </div>

</div>

