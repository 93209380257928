import { Pipe, PipeTransform } from '@angular/core';
import {Passation} from './passation';

@Pipe({
  name: 'passationTextFilter'
})
export class PassationTextFilterPipe implements PipeTransform {

  transform(passations: Passation[],filter:string):Passation[] {
        filter= filter.toUpperCase();
        if(filter == "")
          return passations;
        else return passations.filter(passation=> passation.code.toUpperCase().indexOf(filter)  !== -1
        || passation.passateurName.toUpperCase().indexOf(filter) !==-1
        || this.dateConversion(passation.date.toString()).indexOf(filter) !==-1
        || passation.tags.filter(tag=>tag.name.toUpperCase().indexOf(filter)!==-1 ).length>0
        );
  }

  dateConversion(value:string):string{//emulate |date feature because i can't import it in here
    let splitDate =  value.split('-');
    return (splitDate[2]+'/'+splitDate[1]+'/'+splitDate[0]);
  }

}
