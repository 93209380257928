import { Component, OnInit } from '@angular/core';
import {Passation, Pillar,Item,GenderEnum  } from '../passation';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {UserService} from '../user.service';
import {NgbModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GraphService} from '../graph.service';
import {PassationService} from '../passation.service';

  @Component({
    selector: 'app-passation-review',
    templateUrl: './passation-review.component.html',
    styleUrls: ['./passation-review.component.css']
  })
  export class PassationReviewComponent implements OnInit {


    pillarEnum = Pillar;
    passation?:Passation ;
    passationComment?:String;
    previousPassationList ?: Passation[];
    hasPreviousPassation?: boolean;
    previousPassation:Passation;
    previousPassationChoice ?:Passation = null;
    activeTab:number = -1;
    buildPreviousPassationGraph:boolean= false;
    pillarScore : Map<Pillar,number>;
    isPillarPresent:boolean[] = [false,false,false,false];
    isPillarPresentPrevious:boolean[] = [false,false,false,false];
    pillarEvolution;
    username:String;
    organization:String;
    sendError:String;
    openedModal ?:NgbModalRef;
    requestText:String ='';
    requestEmail:String ='';



    constructor(private passationService : PassationService,private userService:UserService,private graphService:GraphService,private route:ActivatedRoute,private modalService: NgbModal) { }

    ngOnInit(): void {
      let id:Number = +this.route.snapshot.paramMap.get('passationId');
      this.userService.getPassationById(id).subscribe(passation=>{
        this.passation = passation;
        this.passationComment = passation.comment;
        // Aucune idée de pourquoi c'est nécessaire ici et pas sur la page results.
        setTimeout(()=>{
            this.isPillarPresent= this.graphService.createD3PieCharts(this.passation, "review-current-pies");
        },1);
        this.passationService.getPreviousPassations(this.passation.code).subscribe(anwser=> {
            this.previousPassationList = anwser.passations.filter(passation=>passation.id !=this.passation.id &&
                               passation.date< this.passation.date);
            this.hasPreviousPassation = this.previousPassationList.length > 0;
            });
        });

    }

     toGenderString(genderId:number){
      return GenderEnum[genderId];
    }

    onPrintClicked():void{
      window.print();
    }

    openModal(modal):void{
       this.openedModal= this.modalService.open(modal);
    }

    modalClose(){
        this.openedModal.close();
    }

    onValidatePassationChoice(modal,chosenPassation:Passation):void{
      modal.close();
      this.previousPassation = chosenPassation;
      setTimeout(()=>{
          this.isPillarPresentPrevious= this.graphService.createD3PieCharts(this.previousPassation,"previous-passation-pies");
      },1);
      this.activeTab =1;
      this.passationComment = "";
      this.computePillarEvolution();
    }

    cancelPassationComparaison(){
      this.activeTab = -1;
      this.previousPassation = null;
      this.passationComment = this.passation.comment;
    }

    sendEditEmail(){
     this.userService.sendEditEmail(this.passation.id,this.requestText,this.requestEmail).subscribe(anwser=>{
      this.modalClose();
     });
    }


    computePillarEvolution(){
        this.pillarEvolution = [4];
        for (let i=0; i<4;i++){
          this.pillarEvolution[i] = [0,0];
        }
        this.passation.items.forEach(item=>{
            if(item.name != ""){
              this.pillarEvolution[item.pillar][1] += item.score;
            }
        });
        this.previousPassation.items.forEach(item=>{
            if(item.name != ""){
              this.pillarEvolution[item.pillar][0] += item.score;
            }
        });
      }

  }

