<app-menu class="row"></app-menu>
<div class="admin-edit-org-div">
  <div class="admin-edit-org-title">Editer les organisations</div>
</div>
<!--------------------------------- Org table --------------------------------->
<div *ngIf=" organizations != null" class="row">
  <div class="col-md-3"></div>
  <div class=" col-md-6 ">

    <div class="d-flex flex-column justify-content-around align-items-center">
      <div class="d-flex align-items-center">
        <label for="orgShowDisabled">Voir les organisations désactivés :</label>
        <input type="checkbox" id="orgShowDisabled" [(ngModel)]="orgShowDisabled" (ngModelChange)="organisationPage=1">
      </div>
      <div class="d-flex admin-table-row"
           *ngFor="let organization of organizations | enabledOnly:!orgShowDisabled | paginate: {id:'organisationPagination', itemsPerPage: 5, currentPage: organisationPage } ">
        <div [ngClass]="{'admin-disabled':!organization.enabled}" class="d-flex flex-grow-1 align-items-center"
             style="padding-right : 2rem;">
          {{organization.name}}
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-secondary btn-admin-edit"
                  (click)="openUpdateOrgModal(updateOrganizationModal,organization)"> Modifier
          </button>
          <button *ngIf="organization.enabled" class="btn btn-secondary btn-admin-edit"
                  (click)="disableOrganization(organization)"> Désactiver
          </button>
          <button *ngIf="!organization.enabled" class="btn btn-secondary btn-admin-edit"
                  (click)="enableOrganization(organization)"> Activer
          </button>

        </div>

      </div>
      <div *ngIf="organizations.length ==0"><i>Aucune organisation</i></div>
      <pagination-controls id="organisationPagination"
                           (pageChange)="organisationPage = $event"
                           previousLabel="Précédent"
                           nextLabel="Suivant"
      ></pagination-controls>
      <button class="btn btn-primary " (click)="openCreateOrgModal(newOrganizationModal)">Créer une organisation
      </button>
    </div>
  </div>
</div>
<!--------------------------------- separator  --------------------------------->
<div *ngIf=" organizations != null" class="admin-edit-org-div">
  <div>
    <div class="admin-edit-org-title">Editer les éléments d'une organisation</div>
    <label for="editedOrg" class="admin-edit-org-label">Choisir une organisation</label>
    <select (change)="refreshOrganizationItems()" [(ngModel)]="currentOrganizationId" name="editedOrg" id="editedOrg">
      <option selected *ngFor="let organization of organizations | enabledOnly:true" [ngValue]="organization.id">{{organization.name}}
      </option>
    </select>
  </div>
</div>
<!--------------------------------- User table --------------------------------->
<div class="row">
  <div class="col-md-1">
  </div>
  <div class="col-md-6">
    <div class=" d-flex justify-content-around  flex-column flex-md-row align-items-start ">
      <div *ngIf="users != null" class="d-flex  flex-column justify-content-around align-items-center ">
        <h2>Utilisateurs</h2>
        <div class="d-flex align-items-center">
          <label for="userFilter"> filtre :</label>
          <input id="userFilter" type="text" [(ngModel)]="userFilter" (ngModelChange)="userPage=1">
          <label for="userShowDisabled">Voir les utilisateurs désactivés :</label>
          <input type="checkbox" id="userShowDisabled" [(ngModel)]="userShowDisabled" (ngModelChange)="userPage=1">
        </div>
        <div class="d-flex admin-table-row"
             *ngFor="let user of users|userText:userFilter |enabledOnly:!userShowDisabled| paginate: {id:'userPagination', itemsPerPage: 10, currentPage: userPage }">
          <div [ngClass]="{'admin-disabled':!user.enabled}" class="d-flex flex-grow-1 align-items-center"
               style="padding-right : 2rem;">
            {{user.name}}
            <span class="ui-input-hint ">{{roleMap[user.roleId]}}</span>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-secondary btn-admin-edit" [disabled]="user.roleId==2 && isSuperAdmin==false"
                    (click)="openUpdateUserModal(updateUserModal,user)"> Modifier
            </button>
            <button class="btn btn-secondary btn-admin-edit" [disabled]="user.roleId==2 && isSuperAdmin==false"
                    (click)="openUpdatePasswordUserModal(updatePasswordUserModal,user)"> Changer mot de passe
            </button>
            <button *ngIf="user.enabled" class="btn btn-secondary btn-admin-edit" [disabled]="user.roleId >=  userRole"
                    (click)="disableUser(user.id,user.name)"> Désactiver
            </button>
            <button *ngIf="!user.enabled" class="btn btn-secondary btn-admin-edit" [disabled]="user.roleId >=  userRole"
                    (click)="enableUser(user.id,user.name)"> Activer
            </button>
          </div>
        </div>
        <pagination-controls id="userPagination"
                             (pageChange)="userPage = $event"
                             previousLabel="Précédent"
                             nextLabel="Suivant"
        ></pagination-controls>
        <div class="admin-user-create-div">
          <button class="btn btn-primary " (click)="openCreateUserModal(newUserModal)">Créer un utilisateur</button>
        </div>

      </div>
    </div>


  </div>
  <div class="d-flex col-4 flex-column">
    <div>
      <div class="d-flex justify-content-center"><h2>Tags</h2></div>
      <div class="d-flex justify-content-between admin-edit-row"
           *ngFor="let tag of tags| paginate: { id:'tagPagination',itemsPerPage: 10, currentPage: tagPage }">
        {{i}}
        <div class="admin-edit-tag-name"> {{tag.name}}</div>
        <div class="d-flex justify-content-end align-items-center">
          <button (click)="openEditTagModal(editTagModal,tag)" class="btn btn-secondary btn-admin-edit ">Editer</button>
          <button (click)="deleteTag(tag.id,tag.name)" class="btn btn-danger btn-admin-edit ">X</button>
        </div>
      </div>
      <pagination-controls id="tagPagination" (pageChange)="tagPage = $event"></pagination-controls>
      <div class="d-flex justify-content-center" v>
        <button class="btn btn-primary" class="btn btn-primary" (click)="openNewTagModal(createTagModal)">Créer un tag
        </button>
      </div>
      <div class=" d-flex justify-content-around  flex-column flex-md-row align-items-start ">

      </div>
    </div>
  </div>
</div>
<!--------------------------------- Passation table --------------------------------->
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-8 d-flex justify-content-around ">
    <div class="d-flex flex-column align-items-center">
      <h2>Passations</h2>
      <div class="d-flex justify-content-around gap">
        <div class="d-flex gap">
          <label for="passation_order">Trier par </label>
          <select [(ngModel)]="passationSortOption" id="passation_order">
            <option [ngValue]="0">Identifiant</option>
            <option [ngValue]="1">Date</option>
            <option [ngValue]="2">Passateur</option>
          </select>
        </div>
        <div class="d-flex gap">
          <label for="passation_search">Rechercher </label>
          <input (ngModelChange)="passationPage=1" [(ngModel)]="passationFilter" id="passation_search" type="text">
        </div>
      </div>
      <table class="passations-table">
        <tr>
          <th class="admin-edit-cell">
            Identifiant
          </th>
          <th class="admin-edit-cell">
            Date
          </th>
          <th class="admin-edit-cell">
            Passateur
          </th>
          <th class="admin-edit-cell" style="min-width:5rem;">
            Tags
          </th>
        </tr>
        <tr
          *ngFor="let passation of passations|passationTextFilter:passationFilter| passationSortPipe :passationSortOption | paginate: {id:'passationPagination', itemsPerPage: 10, currentPage: passationPage }">
          <td class="admin-edit-cell">
            {{passation.code }}
          </td>
          <td class="admin-edit-cell">
            {{passation.date| date:"dd/MM/yyyy"}}
          </td>
          <td class="admin-edit-cell">
            {{passation.passateurName}}
          </td>
          <td class="admin-edit-cell">
            {{ tagFormat(passation.tags)}}
          </td>
          <td>
            <button (click)="openEditPassation(updatePassationModal,passation)"
                    class="btn btn-secondary btn-admin-edit  ">Editer
            </button>
          </td>
          <td>
            <button (click)="deletePassation(passation.id)"
                    class="btn btn-danger btn-admin-edit btn-edit-item-delete  ">X
            </button>
          </td>
        </tr>
      </table>
      <pagination-controls id="passationPagination"
                           (pageChange)="passationPage = $event"
                           previousLabel="Précédent"
                           nextLabel="Suivant"
      ></pagination-controls>
    </div>

  </div>

</div>

<!-- ---------------------------------Update Passation Modal -------------------------------------->

<ng-template #updatePassationModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <div class=" admin-edit-passation-modal-line justify-content-around">
        <h3 class="admin-modal-title"> Editer une passation</h3>
      </div>

      <form  (ngSubmit)="editPassation()" #editPassationForm="ngForm" class="modal-form-div">

        <div class="admin-edit-passation-modal-line ">
          <label for="editPassationCode" class="ui-label-margin">Identifiant répondant</label>
          <input id="editPassationCode" [(ngModel)]="editedPassation.code"
                 name="code" required minlength="3" maxlength="20" #code="ngModel"/>
        </div>

        <div class="input-error" *ngIf="code.errors?.required">
          Le code répondant est nécessaire.
        </div>
        <div class="input-error" *ngIf="code.errors?.minlength">
          le code doit faire à minima 3 caractères.
        </div>

        <div class="admin-edit-passation-modal-line ">
          <label for="editPassationGender" class="ui-label-margin">Genre</label>
          <select id="editPassationGender" [(ngModel)]="editedPassation.gender" name="gender" class=""
                  #gender="ngModel">
            <option [ngValue]="0">Femme</option>
            <option [ngValue]="1">Homme</option>
            <option [ngValue]="2">NSP</option>
          </select>
        </div>
        <div class="admin-edit-passation-modal-line ">
          <label for="passationNaissance" class="ui-label-margin">Année de naissance</label>
          <input min="1900" max="2021" maxlength="4" type="Number" id="passationNaissance"
                 [(ngModel)]="editedPassation.naissance" name="naissance" class="" #naissance="ngModel" required>
        </div>

        <div class=" admin-edit-passation-modal-line">
          <label class="ui-label-margin">Date passation</label>
          <input type="date" [(ngModel)]="editedPassation.date" name="date" #date="ngModel" required
                 [ngModel]="editedPassation.date | date:'yyyy-MM-dd'"
                 (ngModelChange)="editedPassation.date = $event"
          />
        </div>
        <div class="admin-edit-passation-modal-line">
          <label class="ui-label-margin">Code postal </label>
          <input type="text" [(ngModel)]="editedPassation.codePostal"
                 name="codePostal" pattern="[0-9]*" required size="8" #codePostal="ngModel"/>
        </div>
        <div class="input-error" *ngIf="codePostal.errors?.required">
          Un code postal est nécessaire.
        </div>
        <div class="input-error" *ngIf="codePostal.errors?.pattern">
          Code postal invalide.
        </div>
        <ul>
          <li class="admin-edit-passation-modal-line " *ngFor="let tag of editedPassation.tags | keyvalue">
            <span class="ui-label-margin">{{tag.value.name}}</span>
            <button type="button" class="btn btn-danger" (click)="editPassationRemoveTag(tag.key)">retirer</button>
          </li>
        </ul>
        <div class="admin-edit-passation-modal-line" *ngIf="availableTags.length>0">
          <select [(ngModel)]="editedPassationTagToAdd" name="tagToAdd" #tagToAdd="ngModel">
            <option [ngValue]="tag" *ngFor="let tag of availableTags"> {{tag.name}}</option>
          </select>
          <button type="button" class="btn btn-secondary" [disabled]="editedPassationTagToAdd==null "
                  (click)="editPassationAddTag(editedPassationTagToAdd)">Ajouter un Tag
          </button>
        </div>
        <div class="admin-edit-passation-modal-line" *ngIf="availableTags.length==0">
          <i>Aucun tag supplémentaire ajoutable</i>
        </div>
        <table>
          <tr>
            <th>
              Item
            </th>
            <th>
              Poid
            </th>
            <th>
              note
            </th>
            <th>
              Pilier
            </th>
          </tr>
          <tr *ngFor="let item of editedPassation.items | keyvalue">
            <td>
              <input [ngClass]="{'input-error-input':item.value.name.length==0}" required minlength="1" type="text"
                     [(ngModel)]="item.value.name" name="item{{item.key}}-name"
                     name="itemName{{item.key}}" class="" #itemName{{item.key}}="ngModel">
            </td>
            <td class="d-flex">
              <input class="admin-edit-item-weight-input" type="number" min="0" max="100"
                     [(ngModel)]="item.value.weight" name="itemWeight{{item.key}}" #itemWeight{{item.key}}="ngModel">
              <span class="admin-edit-item-weight-percent-text">%</span>
            </td>
            <td>
              <select [(ngModel)]="item.value.note" name="itemNote{{item.key}}" #itemNote{{item.key}}="ngModel">
                <option hidden [ngValue]="-1">-</option>
                <option [ngValue]="note" *ngFor="let note of notations">{{note}}</option>
              </select>
            </td>
            <td class="d-flex">
              <button type="button" (click)="item.value.pillar= item.value.pillar==0?3:item.value.pillar-1"
                      class="admin-select-arrow"> <
              </button>
              <img title="{{pillarNames[item.value.pillar]}}" class="admin-edit-mini-pillar "
                   *ngIf="item.value.pillar !== null" src="../../assets/pillar-{{item.value.pillar}}.png">
              <button type="button" (click)="item.value.pillar= (item.value.pillar+1)%4" class="admin-select-arrow"> >
              </button>
            </td>
            <td>
              <button type="button" (click)="editPassationRemoveItem(item.key)" class="btn-danger btn-edit-item-delete">
                X
              </button>
            </td>
          </tr>
          <tr>
            <th>
              Poid total
            </th>
            <td [ngClass]="{'input-error':computeSum() !=100}">
              {{computeSum() }}%
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <div class="d-flex">
          <span class="input-error" *ngIf="computeSum() !=100">La somme du poid des éléments doit faire 100% !</span>
        </div>
        <div>
          <button type="button" *ngIf="editedPassation.items.length<5" (click)="editPassationAddItem()"
                  class="btn btn-secondary">Ajouter un élément
          </button>
        </div>
        <label for="editPassationComment" class="ui-label-margin">Commentaire</label>
        <div class="admin-edit-passation-modal-line justify-content-around ">

          <textarea id="editPassationComment" [(ngModel)]="editedPassation.comment" rows="3" cols="30"
                    name="comment" #comment="ngModel">

          </textarea>
        </div>


        <div class=" admin-edit-passation-modal-line justify-content-evenly">
          <button type="button" class="btn btn-warning" (click)=" modalClose()"> Annuler</button>
          <input [disabled]="computeSum() !=100 || isMissingItemName() ||editPassationForm.status === 'INVALID'" type="submit" class="btn btn-danger"
                 value="Sauvegarder">
        </div>
      </form>
    </div>

  </div>
</ng-template>

<!-- ---------------------------------Create Tag Modal -------------------------------------->

<ng-template #createTagModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title"> Créer un nouveau tag</h3>
      <div class="admin-edit-tag-input-div">
        <label for="newTagNameId">nom du tag</label>
        <input type="text" [(ngModel)]="newTagName" id="newTagNameId">
      </div>
      <div class="admin-edit-modal-bottom-div">
        <button type="button" class="btn btn-warning" (click)=" modalClose()"> Annuler</button>
        <button [disabled]="newTagName==''" (click)="submitNewTag()" class="btn btn-danger">Créer</button>
      </div>
      <div class="adminError" *ngIf="newTagError!=''">{{newTagError}}</div>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------Edit Tag Modal -------------------------------------->

<ng-template #editTagModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title"> Modifier un tag</h3>
      <div class="admin-edit-modal-form">
        <div class="admin-edit-modal-row">
          <label for="newTagName">Nouvel intitulé</label>
          <input id="newTagName" type="text" [(ngModel)]="editTagName" id="editTagNameId">
        </div>
        <div class="adminError admin-edit-modal-row" *ngIf="newTagError!=''">{{editTagError}}</div>
      </div>
      <div class="admin-edit-modal-bottom-div">
        <button type="button" class="btn btn-warning" (click)=" modalClose()"> Annuler</button>
        <button [disabled]="editTagName==''" (click)="submitEditTag()" class="btn btn-danger">Modifier</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------New Organization Modal -------------------------------------->

<ng-template #newOrganizationModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title">Créer une organisation</h3>
      <form class="modal-form-div admin-edit-modal-form" ngNativeValidate (ngSubmit)="createOrganization(newOrgName)">
        <div class="admin-edit-modal-row">
          <label for="newOrgFormName">Nom de l'organisation</label>
          <input minlength="4" size="30" type="text" [(ngModel)]="newOrgName" id="newOrgFormName" name="newOrgFormName"
                 required>
        </div>
        <div class="admin-edit-modal-row">
          <div *ngIf="newOrgErrorText!='' " class="adminError"> Erreur : {{newOrgErrorText}}</div>
        </div>
        <div class="admin-edit-bottom-div">
          <button type="button" class="btn btn-warning" (click)="modalClose()"> Annuler</button>
          <input type="submit" class="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------Update Organization Modal -------------------------------------->

<ng-template #updateOrganizationModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title">Modifier {{editedOrgName}}</h3>
      <form class="modal-form-div admin-edit-modal-form" ngNativeValidate (ngSubmit)="updateOrganization(updateOrgId,updateOrgName)">
        <div class="admin-edit-modal-row">
          <label for="updateOrgName">Nom de l'organisation</label>
          <input required minlength="4" size="30" [(ngModel)]="updateOrgName" id="updateOrgName" name="updateOrgName"
                 type="text">
        </div>
        <div class="admin-edit-modal-row">
        <div *ngIf="updateOrgErrorText!='' " class="adminError"> Erreur : {{updateOrgErrorText}}</div>
        </div>
        <div class="admin-edit-modal-bottom-div">
          <button type="button" class="btn btn-warning" (click)="modalClose()">Annuler</button>
          <input type="submit" class="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------New User Modal -------------------------------------->

<ng-template #newUserModal let-modal>
  <div class=" d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title"> Créer un nouvel utilisateur</h3>
      <form (ngSubmit)="createUser(newUser)" ngNativeValidate class="modal-form-div admin-edit-modal-form">
        <div class="admin-edit-modal-row">
          <label for="newUserName">Nom d'utilisateur</label>
          <input required minlength="4" size="30" [(ngModel)]="newUser.name" name="newUserName" id="newUserName"
                 type="text">
        </div>
        <div class="admin-edit-modal-row">
          <label type="email" for="newUserEmail">Email</label>
          <input required size="30" [(ngModel)]="newUser.email" id="newUserEmail" name="newUserEmail" type="email">
        </div>
        <div class="admin-edit-modal-row">
          <label for="newUserPassword">Mot de passe</label>
          <input required [(ngModel)]="newUser.password" id="newUserPassword" minlength="8" name="newUserPassword" type="password">
        </div>
        <div class="admin-edit-modal-row">
          <label for="newUserPasswordConfirm">Confirmez le mot de passe</label>
          <input [ngClass]="{'password-confirm-wrong':newUserPasswordConfirm != newUser.password}" required minlength="8"
                 [(ngModel)]="newUserPasswordConfirm" id="newUserPasswordConfirm" name="newUserPasswordConfirm"
                 type="password">
        </div>
        <div class="admin-edit-modal-row">
          <label for="newUserRole">Role</label>
          <select [(ngModel)]="newUser.roleId" name="newUserRole" id="newUserRole">
            <option [ngValue]="0">Passateur</option>
            <option [ngValue]="4">Rapporteur</option>
            <option [ngValue]="1">Administrateur</option>
            <option [ngValue]="3">Superviseur</option>
          </select>
        </div>
        <div *ngIf="newUser.roleId == 3" class="admin-edit-modal-row">
          <div > Organisations supervisées :</div>
        </div>
        <div *ngIf="newUser.roleId == 3" class="">
          <ng-select  class="ui-ng-select" id = "organisationsSelected" name = "organisationSelected"
                      [items]="supervisableOrganisations|enabledOnly:true"
                      bindLabel="name"
                      [(ngModel)]="newUser.supervisedOrganizations"
                      [addTag]="false"
                      [multiple] ="true">
          </ng-select>
        </div>
        <div class="admin-edit-modal-row" *ngIf="createUserErrorText!='' " class="adminError"> Erreur :
          {{createUserErrorText}}
        </div>
        <div class="admin-edit-bottom-div">
          <button type="button" class="btn btn-warning" (click)=" modalClose()"> Annuler</button>
          <input [disabled]="newUserPasswordConfirm != newUser.password " type="submit" class="btn btn-success"
                 value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------Update User Modal -------------------------------------->


<ng-template #updateUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title"> Modifier {{editedUserName}}</h3>
      <form (ngSubmit)="updateUser(toUpdateUser)" ngNativeValidate class="modal-form-div admin-edit-modal-form">
        <div class="admin-edit-modal-row">
          <label for="updateUserName">Nom d'utilisateur</label>
          <input required minlength="4" size="30" [(ngModel)]="toUpdateUser.name" name="updateUserName"
                 id="updateUserName" type="text">
        </div>
        <div class="admin-edit-modal-row">
          <label for="updateUserEMail">Email</label>
          <input required size="30" [(ngModel)]="toUpdateUser.email" name="updateUserEMail" id="updateUserEMail"
                 type="email">
        </div>
        <div class="admin-edit-modal-row" *ngIf="toUpdateUser.roleId ==2 ">
          <label for="updateUserRoleSA">Role</label>
          <select disabled="true" id="updateUserRoleSA">
            <option selected="true">Super Administrateur</option>
          </select>
        </div>
        <div class="admin-edit-modal-row" *ngIf="toUpdateUser.roleId !=2">
          <label for="updateUserRole">Role</label>
          <select [(ngModel)]="toUpdateUser.roleId" name="updateUserRole" id="updateUserRole">
            <option [ngValue]="0">Passateur</option>
            <option [ngValue]="4">Rapporteur</option>
            <option [ngValue]="1">Administrateur</option>
            <option [ngValue]="3">Superviseur</option>
          </select>
        </div>


        <div class="admin-edit-modal-row">
          <label for="updateUserOrg">Organisation</label>
          <select required (change)="resetSupervizedOrganization(toUpdateUser.organizationId)" [(ngModel)]="toUpdateUser.organizationId" name ="updateUserOrg" id="updateUserOrg">
            <option [ngValue]="organization.id"  *ngFor="let organization of organizations|enabledOnly:true">{{organization.name}}</option>
          </select>
        </div>
        <div *ngIf="toUpdateUser.roleId == 3" class="admin-edit-modal-row">
          <div> Organisations supervisées :</div>
        </div>
        <div *ngIf="toUpdateUser.roleId == 3">
          <ng-select  class="ui-ng-select" id = "organisationsSelected" name = "organisationSelected"
                      [items]="supervisableOrganisations|enabledOnly:true"
                      bindLabel="name"
                      [(ngModel)]="toUpdateUser.supervisedOrganizations"
                      [addTag]="false"
                      [multiple] ="true">
          </ng-select>
        </div>
        <div class="admin-edit-modal-row" *ngIf="updateUserErrorText!='' " class="adminError"> Erreur : {{updateUserErrorText}}</div>
        <div class="admin-edit-bottom-div">
          <button type="button" class="btn btn-warning" (click)="modalClose()"> Annuler</button>
          <input type="submit" class="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- ---------------------------------Update Password Modal -------------------------------------->

<ng-template #updatePasswordUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class="admin-edit-modal-title"> Changer le mot de passe de {{editedUserName}}</h3>
      <form (ngSubmit)="updateUserPassword(updatePwdUserId,updatePwd)" ngNativeValidate class="modal-form-div admin-edit-modal-form">
        <div class="admin-edit-modal-row">
          <label for="updatePwd">Nouveau mot de passe</label>
          <input required minlength="8" [(ngModel)]="updatePwd" id="updatePwd" name="updatePwd" type="password">
        </div>
        <div class="admin-edit-modal-row">
          <label for="updatePasswordConfirm">Confirmez le nouveau mot de passe</label>
          <input [ngClass]="{'password-confirm-wrong':updatePasswordConfirm != updatePwd}" required
                 [(ngModel)]="updatePasswordConfirm" id="updatePasswordConfirm" name="updatePasswordConfirm"
                 type="password">
        </div>
        <div class="admin-edit-modal-row">
          <div *ngIf="updatePwdErrorText!='' " class="adminError"> Erreur : {{updatePwdErrorText}}</div>
          <div class="admin-modal-bottom-div">
        </div>
          <div class="admin-edit-bottom-div">
            <button class="btn btn-warning" (click)=" modalClose()"> Annuler</button>
            <input type="submit" [disabled]="updatePwd != updatePasswordConfirm " class="btn btn-success" value="Valider">
          </div>

        </div>
      </form>
    </div>
  </div>
</ng-template>
