<app-menu class="row"></app-menu>
<div class="row">
  <div class="col-md-2">

  </div>
<!--  <div class="col-md-3 d-flex justify-content-top  flex-column  align-items-center " style="padding : 1em;">
    <h2>Export des données</h2>
    <button (click)="openExportModal(exportModal)" class="btn btn-primary btn-border">
      Exporter des passations
    </button>
  </div>-->

  <div class="col-md-8 d-flex justify-content-around  flex-column flex-md-row align-items-start ">
    <div  *ngIf="users != null"  class="d-flex  flex-column justify-content-around align-items-center ">
      <h2>Utilisateurs</h2>
      <div class="admin-user-create-div">
        <button  class="btn btn-primary " (click)="openCreateUserModal(newUserModal)">Créer un utilisateur</button>
      </div>

      <div class="d-flex align-items-center">
        <label for="userFilter"> filtre :</label>
        <input id="userFilter" type="text" [(ngModel)]="userFilter"  (ngModelChange)="userPage=1">
        <label for="userShowDisabled">Voir les utilisateurs désactivés :</label>
        <input type="checkbox" id="userShowDisabled" [(ngModel)]="userShowDisabled"  (ngModelChange)="userPage=1">
      </div>
      <div  class="d-flex admin-table-row" *ngFor="let user of users|userText:userFilter |enabledOnly:!userShowDisabled | paginate: {id:'userPagination', itemsPerPage: 10, currentPage: userPage } ">
        <div  [ngClass]="{'admin-disabled':!user.enabled}" class ="d-flex flex-grow-1 align-items-center" style="padding-right : 2rem;">
          {{user.name}} : {{user.organizationName}}
          <span class="ui-input-hint ">{{roleMap[user.roleId]}}</span>
        </div>
        <div class="d-flex gap-2 justify-content-end align-items-center">
          <button class="btn btn-secondary btn-admin" [disabled]="user.roleId==2 && isSuperAdmin==false" (click)="openUpdateUserModal(updateUserModal,user)"> Modifier</button>
          <button class="btn btn-secondary btn-admin" [disabled]="user.roleId==2 && isSuperAdmin==false" (click)="openUpdatePasswordUserModal(updatePasswordUserModal,user)"> Changer mot de passe</button>
          <button *ngIf="user.enabled" class="btn btn-secondary btn-admin" [disabled]="user.roleId >=  userRole" (click)="disableUser(user.id,user.name)"> Désactiver</button>
          <button *ngIf="!user.enabled" class="btn btn-secondary btn-admin" [disabled]="user.roleId >=  userRole" (click)="enableUser(user.id,user.name)"> Activer</button>
        </div>

      </div>
      <pagination-controls id="userPagination"
                           (pageChange)="userPage = $event"
                           previousLabel="Précédent"
                           nextLabel="Suivant"
      ></pagination-controls>

    </div>
  </div>



</div>


<ng-template #newUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Créer un nouvel utilisateur</h3>
      <form (ngSubmit)="createUser(newUser)" ngNativeValidate class ="modal-form-div">
        <label for="newUserName">Nom d'utilisateur</label>
        <input required minlength="4" size="30" [(ngModel)]="newUser.name" name="newUserName" id ="newUserName" type="text">
        <label type="email" for="newUserEmail">Email</label>
        <input required size= "30" [(ngModel)]="newUser.email" id="newUserEmail" name ="newUserEmail"type="email">
        <label for="newUserPassword">Mot de passe</label>
        <input required  [(ngModel)]="newUser.password" id ="newUserPassword" name ="newUserPassword" type="password">
        <label for="newUserPasswordConfirm">Confirmez le mot de passe</label>
        <input  [ngClass]="{'password-confirm-wrong':newUserPasswordConfirm != newUser.password}" required  [(ngModel)]="newUserPasswordConfirm" id ="newUserPasswordConfirm" name ="newUserPasswordConfirm" type="password">
        <div *ngIf ="createUserErrorText!='' " class="adminError"> Erreur : {{createUserErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button type="button" class ="btn btn-warning"  (click)=" modalClose()" > Annuler </button>
          <input [disabled]="newUserPasswordConfirm != newUser.password " type="submit" class ="btn btn-success"  value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #updateUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Modifier {{editedUserName}}</h3>
      <form  (ngSubmit)="updateUser(toUpdateUser)" ngNativeValidate class="modal-form-div" >
        <label for="updateUserName">Nom d'utilisateur</label>
        <input required minlength="4" size="30" [(ngModel)]="toUpdateUser.name" name="updateUserName" id="updateUserName" type="text">
        <label for="updateUserEMail">Email</label>
        <input required  size= "30" [(ngModel)]="toUpdateUser.email" name ="updateUserEMail" id="updateUserEMail" type="email">
        <div *ngIf="toUpdateUser.roleId ==2 ">
          <label for="updateUserRoleSA" >Role</label>
          <select disabled="true" id ="updateUserRoleSA">
            <option selected ="true">Super Administrateur</option>
          </select>
        </div>
        <div *ngIf="toUpdateUser.roleId !=2 && isSuperAdmin ==true">

          <label for="updateUserRole">Role</label>
          <select [(ngModel)]="toUpdateUser.roleId" name="updateUserRole" id="updateUserRole">
            <option [ngValue]="0">Passateur</option>
            <option [ngValue]="4">Rapporteur</option>
            <option [ngValue]="1">Administrateur</option>

          </select>
        </div>
        <div *ngIf ="updateUserErrorText!='' " class="adminError"> Erreur : {{updateUserErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button type="button" class ="btn btn-warning"  (click)="modalClose()" > Annuler </button>
          <input type="submit" class ="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #updatePasswordUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Changer le mot de passe de {{editedUserName}}</h3>
      <form (ngSubmit)="updateUserPassword(updatePwdUserId,updatePwd)" ngNativeValidate class="modal-form-div">
        <label for="updatePwd">Nouveau mot de passe</label>
        <input required minlength="8"  [(ngModel)]="updatePwd" id ="updatePwd" name ="updatePwd" type="password">
        <label for="updatePasswordConfirm">Confirmez le nouveau mot de passe</label>
        <input  [ngClass]="{'password-confirm-wrong':updatePasswordConfirm != updatePwd}" required
                [(ngModel)]="updatePasswordConfirm" id ="updatePasswordConfirm" name ="updatePasswordConfirm" type="password">
        <div *ngIf ="updatePwdErrorText!='' " class="adminError"> Erreur : {{updatePwdErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button type="button" class ="btn btn-warning"  (click)=" modalClose()" > Annuler </button>
          <input type="submit" [disabled]="updatePwd != updatePasswordConfirm " class ="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #exportModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title">Exporter</h3>
      <form class="modal-form-div" ngNativeValidate >
        <label *ngIf="isSuperAdmin==true" for="updateOrgName">Organisation à exporter</label>
        <select *ngIf="isSuperAdmin==true" [(ngModel)]="exportOrganisationId" name="exportOrganisation" id="exportOrganisation">
          <option [ngValue]="-1">Toutes</option>
          <option  *ngFor="let organization of organizations" [ngValue]="organization.id">{{organization.name}}</option>
        </select>

        <label for="updateOrgName">Date de départ de l'export</label>
        <div>
          <input type="radio" id="exportAll" [(ngModel)]="exportDateType" name ="exportDateType" value="exportAll" >
          <label for="exportAll">Tout exporter</label><br>
        </div>
        <div>
          <input type="radio" id="exportFromDate" [(ngModel)]="exportDateType"  name ="exportDateType"  value="exportFromDate" >
          <label for="exportFromDate">Exporter à partir de</label><br>
        <input  [disabled] ="exportDateType == 'exportAll'" type="date"  (ngModelChange)="exportDate = $event"
                  name ="date"
                [(ngModel)]="exportDate"
                [ngModel]="exportDate | date:'yyyy-MM-dd'" #date="ngModel">
        </div>
        <div class ="admin-modal-bottom-div">
          <button type="button" class ="btn btn-warning"  (click)="modalClose()">Retour</button>
          <a target="_blank"  class="btn btn-success btn-border" download="content.txt" href="{{computeExportUrl()}}">Exporter</a>
        </div>
      </form>
    </div>
  </div>
</ng-template>
