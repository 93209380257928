<div class="d-flex align-items-center header-wrapper hidePrint">
  <div class="col-1"></div>
  <div><img src="../../assets/logo_atev.png" class="header-logo" /></div>
  <div class="d-flex flex-grow-1 justify-content-end align-items-center">
 </div>
  <div class="col-1"></div>
</div>


<div class="d-flex flex-column justify-content-around align-items-center flex-grow-1 hidePrint">
  <div class="d-flex flex-column justify-content-center align-items-center ui-login-header">
    <h1>Bienvenue sur l’application du baromètre qualité de vie par l’ATEV !</h1>
    <div class="login-logo d-flex  align-items-center justify-content-center"><img class="logo-qualiviscope" src="../../assets/logo_qualiviscope.png" /></div>

    <div  style="font-size:.9em;">plus d'informations sur <a href="https://atelierdelevaluation.fr/" class="link" target="_new">https://atelierdelevaluation.fr</a></div>
  </div>
</div>
<form class="container form-passation" (ngSubmit)="login(connectId,connectPwd)"  #loginForm="ngForm">
  <div class="form-group row">
    <label for="connectId" class="col-12 col-md-4 col-form-label">Identifiant (email) </label>
    <div class="col-12 col-md-8">
      <input required name="connectId" [(ngModel)] = "connectId" id ="connectId" type = "email"  [email]="true" class="form-control login-fields">
    </div>
  </div>

  <div class="form-group row">
    <label for="connectPwd" class="col-12 col-md-4 col-form-label">Mot de passe</label>
    <div class="col-12 col-md-8">
      <input required  name="connectPwd" [(ngModel)] = "connectPwd"  id = "connectPwd" type = "password" class="form-control login-fields">
    </div>
  </div>
  <div class="form-group row">
    <div class="col-12 col-md-6 offset-md-4">
      <input type="checkbox" (click)="toggleShowPassword()"id="togglePwd"> <label for="togglePwd" style="margin-left:5px;"> Voir le mot de passe</label>
    </div>
  </div>
  <div class="login-error-text" *ngIf="connectionErrorText!=''"> {{connectionErrorText}}</div>
  <div class="d-flex align-items-center justify-content-center">
    <input class="btn btn-primary login-validate" [disabled]="loginForm.status==='INVALID'" type="submit" value="Valider">
  </div>
</form>


<div class="d-flex justify-content-center align-items-center flex-column footer-wrapper  hidePrint">
  <div>Une réalisation du Keepic'Lab à Poitiers (86).</div>
  <div>Rendez-nous visite sur &nbsp; <a class="ui-login-link" href="https://www.keepic.net" target="_new">www.keepic.net</a> !</div>
</div>
