<app-menu class="row"></app-menu>

<div *ngIf="passation" class="result-wrapper">
  <!-- header rapport -->
  <div class="d-flex justify-content-between align-items-center result-header">
    <div class="d-none d-md-block d-print"><h2>Fiche synthèse</h2></div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="result-title-div">Identifiant:<strong>{{passation.code}}</strong></div>
      <div class="result-title-div">Naissance:<strong>{{passation.naissance}}</strong></div>
      <div class="result-title-div">Genre:<strong>{{ toGenderString(passation.gender)}}</strong></div>
    </div>
    <div class="d-none d-md-block d-print">
      <img src="assets/logo_atev.png" class="result-logo-atev-img">
    </div>
  </div>


  <div class="result-passations-wrapper">
    <!-- rendu passations -->
    <div class="d-flex flex-column result-passation-width  " *ngIf="previousPassation != null"
         [ngClass]="{ 'width-comparaison-active':previousPassation != null}">
      <div class="result-passation result-padding">
        <div class="result-passation-resume">
          <div>Date: <strong>{{previousPassation.date| date:"dd/MM/yyyy"}}</strong></div>
          <div>Tags associés : <span *ngFor="let tag of previousPassation.tags">{{tag.name}} </span></div>
          <div>Operateur/trice : {{previousPassation.passateurName}} <span
            *ngIf="previousPassation.organizationName !=''">   {{previousPassation.organizationName}}  </span></div>
          <!-- NG IF entité sur span -->
        </div>
        <div class="result-passation-score d-flex justify-content-center align-items-center result-padding">
          <div>Eléments de la qualité de la vie</div>
        </div>
        <div class="result-passation-items-list result-padding">
          <div class="d-flex align-items-center justify-content-around result-item" style="color:rgb(185, 185, 185);">
            <div class="flex-grow-1">Eléments</div>
            <div>Part</div>
            <div>Note</div>
          </div>
          <div class="result-passation-items-item" *ngFor="let item of previousPassation.items">
            <div class="d-flex align-items-center justify-content-around result-item" *ngIf="item.name !== ''">
              <div class="result-pillar-mini-div">
                <img src="../../assets/pillar-{{item.pillar}}.png">
              </div>
              <div class="flex-grow-1">{{item.name}}</div>
              <div> {{item.weight}}%</div>
              <div><b>{{item.note}}</b><em>/10</em></div>
            </div>
          </div>
          <div class="d-flex justify-content-end" style="padding-right:1em;  ">
            <div class="">Score global qualité de vie : <b>{{previousPassation.totalScore.toFixed(1)}}/100</b></div>
          </div>
        </div>
        <div class="result-passation-graph result-padding" id="previous-passation-pies">
          <div class="result-passation-titre-section">Importance des piliers</div>
          <div class="result-pie pillarProportion"></div>
          <div class="result-passation-titre-section">Scores de qualité de vie</div>
          <div class="result-pie pillarScoreProportion"></div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column result-passation-width result-passation-comparaison"
         [ngClass]="{'result-passation-active':activeTab==1,'width-comparaison-active':previousPassation != null }">
      <div class="result-passation result-padding">
        <!-- passation de base, toujours présente, l'autre vient par dessus-->
        <div class="result-passation-resume">
          <div>Date: <strong>{{passation.date| date:"dd/MM/yyyy"}}</strong></div>
          <div>Tags associés : <span *ngFor="let tag of passation.tags">{{tag.name}} </span></div>
          <div>Operateur/trice : {{passation.passateurName}} <span *ngIf="passation.organizationName !='' "> {{passation.organizationName}}</span>
          </div>
        </div>
        <div class="result-passation-score d-flex justify-content-center align-items-center result-padding">
          <div>Eléments de la qualité de la vie</div>
        </div>
        <div class="result-passation-items-list result-padding">
          <div class="d-flex align-items-center justify-content-around result-item" style="color:rgb(185, 185, 185);">
            <div class="flex-grow-1">Eléments</div>
            <div>Part</div>
            <div>Note</div>
          </div>
          <div class="result-passation-items-item" *ngFor="let item of passation.items">
            <div class="d-flex align-items-center justify-content-around result-item" *ngIf="item.name !== ''">
              <div class="result-pillar-mini-div">
                <img src="../../assets/pillar-{{item.pillar}}.png">
              </div>
              <div class="flex-grow-1">{{item.name}}</div>
              <div> {{item.weight}}%</div>
              <div><b>{{item.note}}</b><em>/10</em></div>
            </div>
          </div>
          <div class="d-flex justify-content-end" style="padding-right:1em;  ">
            <div class="">Score global qualité de vie : <b>{{passation.totalScore.toFixed(1)}}/100</b></div>
          </div>
        </div>

        <div class="result-passation-graph result-padding " id="review-current-pies">
          <div class="result-passation-titre-section">Importance des piliers</div>
          <div class="result-pie pillarProportion"></div>
          <div class="result-passation-titre-section">Scores de qualité de vie</div>
          <div class="result-pie pillarScoreProportion"></div>
        </div>
      </div>
    </div>

  </div>
  <div class="d-flex flex-wrap align-items-center justify-content-center">
    <div *ngFor="let pillarBoolean of isPillarPresent;let index = index">
      <div *ngIf="pillarBoolean ||(previousPassation != null && isPillarPresentPrevious[index])" class="graph-caption-item graph-caption-pillar-{{index}}">
        {{pillarEnum[index]}}
      </div>
    </div>
  </div>
  <div *ngIf="previousPassation != null"
       class="d-flex flex-column align-items-center justify-content-center  result-comparaison">
    <h3>Evolution des piliers</h3>
    <div class="d-flex flex-column flex-md-row result-comparaison-items">
      <div *ngFor="let evolution of pillarEvolution;let index = index">
        <div *ngIf="isPillarPresent[index] || isPillarPresentPrevious[index] " class="d-flex align-items-center justify-content-center">
          <div class="result-pillar-mini-div">
            <img src="../../assets/pillar-{{index}}.png">
          </div>
          <div class="result-comparaison-text">
            de {{evolution[0].toFixed(1)}} à {{evolution[1].toFixed(1)}}
          </div>
        </div>
        <div *ngIf="!isPillarPresent[index] && !isPillarPresentPrevious[index]  " class="d-flex align-items-center justify-content-center">
          <div class="result-pillar-mini-div">
            <img src="../../assets/pillar-{{index}}.png">
          </div>
          <div class="result-comparaison-text">
            Pilier non cité
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="result-passation-details d-flex justify-content-around">
    <div class="result-passation-details-commentaire col-10">
      <div>Commentaires:</div>
      <form>
        <div [(ngModel)]="passationComment" name="commentInput" class="form-control commentaire-expandable"
             role="textbox" contenteditable></div>
      </form>
      <!-- <textArea class="form-control" rows="4" cols="" ></textArea> -->
    </div>

    <!-- <div class="result-passation-details-nom col-4">
    <div>Nom: <em>(donnée non sauvegardée)</em></div>
    <input  class="form-control"  type ="text">
  </div> -->
  </div>
  <!-- actions -->
  <div class="passation-comparaison hidePrint">
    <button *ngIf="previousPassation != null" (click)="activeTab=0"
            class="btn btn-secondary passation-comparaison-picker" [ngClass]="{'active':activeTab==0}">Précédente
    </button>
    <button *ngIf="previousPassation != null" (click)="activeTab=1"
            class="btn btn-secondary passation-comparaison-picker" [ngClass]="{'active':activeTab==1}">Aujourd'hui
    </button>

    <button [disabled]="hasPreviousPassation== false" *ngIf="previousPassation == null" (click)="openModal(modal)"
            class="btn btn-primary">Comparer
    </button>
    <button *ngIf="previousPassation != null" (click)="cancelPassationComparaison()" class="btn btn-primary">Annuler
      comparaison
    </button>
    <br/>
    <!-- switch/tabs pour version mobile ICI pour afficher l'une ou l'autre passation-->
  </div>
  <div
    class="passation-button-div passation-actions d-flex flex-column flex-md-row flex-wrap justify-content-center align-items-center hidePrint">
    <button routerLink="/user-passations" class="btn btn-primary">
      Retour
    </button>
    <button (click)="onPrintClicked()" class="btn btn-primary">
      Imprimer
    </button>
    <button (click)="openModal(mailingModal)" class="btn btn-primary">
      Demander une modification
    </button>

  </div>
</div>

<ng-template #modal let-modal>
  <div>
    <div *ngIf="previousPassationList != null">
      <div class="modal-header ">
        <h3> Comparer des passations</h3>
      </div>
      <div class="modal-body d-flex flex-column justify-content-center">
        <div><i>seules les passations antérieures sont proposées</i></div>
        <select [(ngModel)]="previousPassationChoice">
          <option hidden [ngValue]="null">Choisir une entrée antérieure...</option>
          <option [ngValue]="passation" *ngFor="let passation of previousPassationList"> {{
            passation.date.split('T')[0]|date :"dd/MM/yyyy"}}
            <span *ngFor="let tag of passation.tags">{{tag.name}} </span>
          </option>
        </select>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="modal.close()"> Annuler</button>
        <button class="btn btn-primary" [disabled]="previousPassationChoice == null"
                (click)="onValidatePassationChoice(modal,previousPassationChoice)"> Choisir
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #mailingModal let-modal>
  <div>
    <div class="modal-header d-flex flex-column ">
      <h3> Demander une modification</h3>
      <i>Un message sera envoyé à un Administrateur afin  d'effectuer la modification.</i>
    </div>
    <div class="modal-body reporting-mailing-body">
      <div class="d-flex justify-content-between">
        <span>Passation à corriger : </span>
        <span class="reporting-mailing-passation-identifier">Passation n°{{passation.id}} réalisée le {{passation.date| date:"dd/MM/yyyy"}}</span>
      </div>
      <div class="d-flex flex-column  justify-content-between">
        <label for="contactEmail">Email de contact : </label>
        <input  [(ngModel)]="requestEmail" class="" type="email" id="contactEmail" name = "contactEmail"  [email]="true" >
      </div>
      <label for="correction-text">Expliquez la modification à effectuer (350 caractères max) : </label>
      <textarea  [(ngModel)]="requestText" class="reporting-mailing-text-area" row="30" col="10" maxlength="350" id="correction-text">
      </textarea >
      <div class="d-flex justify-content-between">
        <button (click)="modalClose()" class ="btn btn-warning">Annuler</button>
        <button [disabled]="requestText==''" (click)="sendEditEmail()" class="btn btn-success">Envoyer</button></div>
    </div>
  </div>
</ng-template>


