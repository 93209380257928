import { Pipe, PipeTransform } from '@angular/core';
import {Passation} from './passation';
@Pipe({
  name: 'passationSortPipe'
})
export class PassationSortPipePipe implements PipeTransform {

  transform(passations: Passation[],orderType:Number):Passation[] {
    let sortedPassation:Passation[];
    if(passations != null){
      //ordre alphabétique des codes passations
      if (orderType ==0){
        sortedPassation = passations.sort((n1,n2)=>{
            if (n1.code.toUpperCase() > n2.code.toUpperCase()) {
                return 1;
            }

            if (n1.code.toUpperCase() < n2.code.toUpperCase()) {
                return -1;
            }
            return 0;
        });
      }
      // date par ordre décroissant
      else if (orderType ==1){
        sortedPassation = passations.sort((n1,n2)=>{
            if (n1.date < n2.date) {
                return 1;
            }

            if (n1.date > n2.date) {
                return -1;
            }
            return 0;
        });
      }
      // nom du passateur
      else{
      sortedPassation = passations.sort((n1,n2)=>{
                if (n1.passateurName.toUpperCase() > n2.passateurName.toUpperCase()) {
                    return 1;
                }

                if (n1.passateurName.toUpperCase() < n2.passateurName.toUpperCase()) {
                    return -1;
                }
                return 0;
            });
      }
    }

    return sortedPassation;
  }

}
