import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ContenteditableModule} from '@ng-stack/contenteditable';

import { HttpClientModule,HTTP_INTERCEPTORS  } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRootingModule } from './app-rooting.module';
import { PassationComponent } from './passation/passation.component';
import { InformationComponent } from './information/information.component';
import { ItemsComponent } from './items/items.component';
import { ItemsWeightComponent } from './items-weight/items-weight.component';
import { ItemsNoteComponent } from './items-note/items-note.component';
import { ItemsPillarComponent } from './items-pillar/items-pillar.component';
import { ResultsComponent } from './results/results.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminComponent } from './admin/admin.component';
import { ErrorInterceptor} from './error.interceptor';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UserTextPipe } from './user-text.pipe';
import { EnabledPipe } from './enabled.pipe';
import { AdminEditComponent } from './admin-edit/admin-edit.component';
import { NgxPaginationModule} from 'ngx-pagination';
import { PassationSortPipePipe } from './passation-sort-pipe.pipe';
import { PassationTextFilterPipe } from './passation-text-filter.pipe';
import { UserPassationsComponent } from './user-passations/user-passations.component';
import { PassationReviewComponent } from './passation-review/passation-review.component';
import { MenuComponent } from './menu/menu.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ChartsModule } from 'ng2-charts';
import { PassationUserTextFilterPipe } from './passation-user-text-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PassationComponent,
    InformationComponent,
    ItemsComponent,
    ItemsWeightComponent,
    ItemsNoteComponent,
    ItemsPillarComponent,
    ResultsComponent,
    AdminComponent,
    HomeComponent,
    LoginComponent,
    UserTextPipe,
    EnabledPipe,
    AdminEditComponent,
    PassationSortPipePipe,
    PassationTextFilterPipe,
    UserPassationsComponent,
    PassationReviewComponent,
    MenuComponent,
    ReportingComponent,
    PassationUserTextFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRootingModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    HttpClientModule,
    ContenteditableModule,
    NgxPaginationModule,
    ChartsModule,

  ],
  providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
