import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import  {serverURL} from "./config";
import {UserDetails,UserRequest,Organization} from "./user.service";
import {TagView} from './passation.service';
import {Passation,Tag, Item} from './passation';

@Injectable({
  providedIn: 'root'
})
export class AdminService {



  constructor(private http: HttpClient) {

   }

   public getUsers():Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/admin/user/getAll",{withCredentials: true });
   }

    public getOrganizations():Observable<Object>{
       return this.http.get<Object>(serverURL+"/api/superAdmin/organization/getAll",{withCredentials: true });
    }
    public createOrganization(name:String):Observable<Organization>{
      return this.http.put<Organization>(serverURL+"/api/superAdmin/organization",{'name':name},{withCredentials: true });
    }

    public createUser(newUser:UserRequest):Observable<UserDetails>{
      return this.http.put<UserDetails>(serverURL+"/api/admin/user",newUser,{withCredentials: true });
    }
    public updateUser(user:UserRequest):Observable<Object>{
      return this.http.post<UserDetails>(serverURL+"/api/admin/user",user,{withCredentials: true });
    }

    public updateUserPassword(userId:Number,pwd:String){
     return this.http.get<object>(serverURL+"/api/admin/user/pwd/"+userId+"/"+pwd,{withCredentials: true });
    }

    public updateOrganization(id:Number,name:String):Observable<Organization>{
      return this.http.post<Organization>(serverURL+"/api/superAdmin/organization",{'id':id,'name':name},{withCredentials: true });
    }
  /*
    public rebind():Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/organization/rebind",{withCredentials: true });
    }
      public rebindTag():Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/organization/rebindTag",{withCredentials: true });
    }*/

    public setEnabledOrganization(id:Number,enabled:Boolean):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/organization/"+id+"/setEnabled/"+enabled,{withCredentials: true });
    }

    public setEnabledUser(id:Number,enabled:Boolean):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/admin/user/"+id+"/setEnabled/"+enabled,{withCredentials: true });
    }

    public getPassation(id:Number):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/passation/"+id,{withCredentials: true });
    }

    public updatePassation(passation:Passation):Observable<Object>{
      return this.http.post<Object>(serverURL+"/api/superAdmin/passation",passation,{withCredentials: true });
    }

    public getPassationsByOrganization(id:Number):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/passation/getByOrganization/"+id,{withCredentials: true });
    }

    public deletePassation(id:Number):Observable<Object>{
      return this.http.delete<Object>(serverURL+"/api/superAdmin/passation/"+id,{withCredentials: true });
    }

    public getTagsByOrganization(id:Number) :Observable<TagView>{
      return this.http.get<TagView>(serverURL+"/api/superAdmin/tag/getByOrganization/"+id,{withCredentials: true});
    }

    public getUsersByOrganization(id:Number) :Observable<UserDetails>{
      return this.http.get<UserDetails>(serverURL+"/api/superAdmin/user/getByOrganization/"+id,{withCredentials: true});
    }

    public createTag(tagName:String,orgId:Number):Observable<Object>{
      return this.http.post<Object>(serverURL+"/api/superAdmin/tag/", {'name':tagName,'orgId':orgId}, {withCredentials: true});
    }

    public updateTag(tagName,tagId,orgId){
      return this.http.put<Object>(serverURL+"/api/superAdmin/tag/", {'name':tagName,'id':tagId,'orgId':orgId}, {withCredentials: true})
    }

    public  deleteTag(tagId:Number):Observable<Object>{
      return this.http.delete<Object>(serverURL+"/api/superAdmin/tag/delete/"+tagId,{withCredentials: true});

    }

    public getAvailableReportData():Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/report/getAvailableData",{withCredentials: true});
    }

    public generateReport(type:Number,start:Date,stop:Date ,tags:Tag[],orgIdList):Observable<Object>{
      return this.http.post<Object>(serverURL+"/api/report/generate",{
        reportType:type,
        startDate:start,
        stopDate:stop,
        tags:tags,
        organizationIds : orgIdList,
      },{withCredentials:true})
    }
      public generateExport(start:Date,stop:Date ,tags:Tag[],orgIdList):Observable<Object>{
      return this.http.post<Object>(serverURL+"/api/report/export",{
        startDate:start,
        stopDate:stop,
        tags:tags,
        organizationIds : orgIdList,
      },{withCredentials:true})
    }
}
