import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import {Passation, Pillar,Item,GenderEnum  } from './passation';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor() { }



  createD3PieCharts(passation:Passation,divId:String):boolean[]{
    let legendThreshold = 15;
    let isPillarPresent:boolean[] = [false,false,false,false];
    let pillarProportionData = [0,0,0,0];
    let pillarScoreData = [0,0,0,0,0];
    let pillarProportionNames = [];
    let pillarScoreNames  = [];

    passation.items.forEach(item=>{
      if(item.name != ""){
          pillarProportionData[item.pillar] += item.weight;
          pillarScoreData[item.pillar] += item.score;
      }
    });
    for (let id =0;id<4; id++)
    {
      if (pillarProportionData[id] >0){
        isPillarPresent[id] = true;
      }
      if (pillarProportionData[id] ==0){
        pillarProportionNames.push(" ");
      }
      else{
        let proportionText = pillarProportionData[id].toFixed(1)+"%";
        pillarProportionNames.push(proportionText);
      }
      if (pillarProportionData[id] ==0 ){
        pillarScoreNames.push("");
      }
      else{
      pillarScoreNames.push(pillarScoreData[id].toFixed(1)+"");
      }
    }
    if (passation.totalScore <100)
    {
      let reste = 100-passation.totalScore;
      pillarScoreData[4] = reste;
      pillarScoreNames.push("");
    }
    let colors = ["#81b3a3","#e4526d" ,"#f8cf63","#c8d538", "#eaeaea"];
    let width = 300;//element.getBoundingClientRect().width;
    let height = 200;//element.getBoundingClientRect().height;
    let legendWidth = 55;
     // -----------proportion graph ----------------
    let radius = height/2*0.9;
    let svg = d3.select("#"+divId+" .pillarProportion")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 "+(width)+" "+height)
      .classed("svg-content", true);
    let pieGen = d3.pie<Number>().sort(null);
    let g = svg.append("g").attr("transform", "translate("+(width/2)+","+ height/2 +")");
    var arcGen  = d3.arc()
                  .innerRadius(0)
                  .outerRadius(radius);
    let arcsData = pieGen(pillarProportionData);
    let arcLabel = <any>d3.arc().innerRadius(0).outerRadius(radius);

    let testArc = g.attr("stroke", "white")
    .selectAll("path")
    .data(arcsData)
          .join("path")
          .attr("fill", (d,i) => colors[i])
          .attr("d", <any>arcGen)
     ;

    svg.append("g").attr("transform", "translate("+(width/2)+","+ height/2 +")")
      .attr("font-family", "sans-serif")
      //.attr("font-size", "large")
      .attr("text-anchor", "middle")
      .selectAll("text").data(arcsData)
      .join("text")
      .text((d,i)=> pillarProportionData[i]>legendThreshold? pillarProportionNames[i]:"")
      .attr("transform",(d)=>   'translate('+arcLabel.centroid(d)+')')
    ;
    let legend = svg.append('g').attr('transform', 'translate('+(width-legendWidth)+','+30+')');
    let boxCount=0;
    for (let i=0;i<4;i++){
      if (pillarProportionData[i]<legendThreshold && pillarProportionData[i]>0){
        legend.append('rect')
          .attr('y', d => 20 * boxCount * 1.8)
          .attr('width', 14)
          .attr('height', 10)
          .attr('fill', colors[i])
          .attr('stroke', 'grey')
          .style('stroke-width', '1px');
        legend.append('text')
         .text(d => pillarProportionNames[i])
         .attr('x', 17)
         .attr('y', d => 20 * boxCount * 1.8+10)
         .style('font-family', 'sans-serif')
         .style('font-size', `13px`);

        boxCount++;
      }
    }


    // -----------score graph ----------------
    svg = d3.select("#"+divId+" .pillarScoreProportion")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 "+width+" "+height)
      .classed("svg-content", true);

     pieGen = d3.pie<Number>().sort(null);//.value( item => item.weight);
     g = svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")");
     arcGen  = d3.arc()
                  .innerRadius(0)
                  .outerRadius(radius);
     arcsData = pieGen(pillarScoreData);
     arcLabel = <any>d3.arc().innerRadius(0).outerRadius(radius);

     testArc = g.attr("stroke", "white")
    .selectAll("path")
    .data(arcsData)
          .join("path")
          .attr("fill", (d,i) => colors[i])
          .attr("d", <any>arcGen)
     ;

    svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")")
    .attr("font-family", "sans-serif")
    .attr("font-size", "large")
    .attr("text-anchor", "middle")
    .selectAll("text").data(arcsData)
    .join("text")
    .text((d,i)=> pillarScoreData[i]>legendThreshold? pillarScoreNames[i]:"")
    .attr("transform",(d)=>'translate('+arcLabel.centroid(d)+')');

    legend = svg.append('g').attr('transform', 'translate('+(width-legendWidth)+','+30+')');
    boxCount=0;
    for (let i=0;i<4;i++){
      if (pillarScoreData[i]<legendThreshold && isPillarPresent[i])
      {
        legend.append('rect')
              .attr('y', d => 20 * boxCount * 1.8)
              .attr('width', 14)
              .attr('height', 10)
              .attr('fill', colors[i])
              .attr('stroke', 'grey')
              .style('stroke-width', '1px');
        legend.append('text')
                           .text(d => pillarScoreNames[i])
                           .attr('x', 17)
                           .attr('y', d => 20 * boxCount * 1.8+10)
                           .style('font-family', 'sans-serif')
                           .style('font-size', `13px`);

        boxCount++;
      }
    }
    return isPillarPresent;
  }
}
