  import { Component, OnInit } from '@angular/core';
  import {Passation,Tag, Item} from '../passation';
  import {PassationService} from '../passation.service';
  import {AdminService} from '../admin.service';
  import {Chart,ChartData, ChartOptions, ChartType } from 'chart.js';
  import { saveAs } from "file-saver";
  import * as d3 from 'd3';
  import * as XLSX from 'xlsx';
  import {UserService,Organization} from '../user.service';
  import {NgbModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
  declare var saveSvgAsPng: any;

  @Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.css']
  })
  export class ReportingComponent implements OnInit {

    // pillar Count Pie
     pillarCountPieLabels: string[] = ['1 pilier', '2 piliers', '3 piliers','4 piliers'];
     pillarCountPieData: number[] = [300, 500, 100];
     pillarCountPieType = 'pie';
     pieChartColors: Array < any > = [{
        backgroundColor: ['#f7cf61', '#81b3a3', '#a53e51','#e4526d'],
     }];
     PillarCountPieOptions = {
       responsive: true,
       maintainAspectRatio: true,
       title: {
                  display: true,
                  text: 'Nombre de piliers cités (%)'
                }
        };

    organizations?: Organization[];
    selectedOrganizations?: Organization[];
    popUpSelectedOrganizations ?: boolean[];
    canSeeMultipleOrganisations:boolean;
    orgModalReference?:NgbModalRef;
    selectAllOrg:boolean=false;
    isExport:boolean=false;

    currentReport:Object = null;
    noDate:boolean=true;
    startDateValue:Date;
    stopDateValue:Date;
    reportTags:Tag[] =[];
    tagList:Tag[];
    filteredTagList:Tag[];
    reportTagToAdd:Tag = null;
    reportType :number =0;
    infoTexts:string[]=[
     "Génère un rapport rassemblant toutes les passations émises sur une période donnée.",
     "Génère un rapport rassemblant toutes les premières passations des usagers présents ( c'est-à-dire ayant effectué une passation) dans la période donnée. Cette première passation peut être antérieure à cette période.",
     "Génère un rapport comparatif entre la première et dernière passation des usagers dans une période donnée. L'usager doit avoir effectué au moins 2 passations."
    ];

    pillars:string[] = [    "Sécurité et cadre de vie",
                   "Santé et pratiques de santé",
                   "Vie affective et relation aux autres",
                   "Ressources personnelles et loisirs"]
    constructor(private userService:UserService, private passationService:PassationService,
                private adminService:AdminService,private modalService: NgbModal) { }

    ngOnInit(): void {
      this.selectedOrganizations = [];
      this.startDateValue = new Date();
      this.startDateValue.setFullYear(this.startDateValue.getFullYear()-1);
      this.stopDateValue = new Date();
      this.canSeeMultipleOrganisations = this.userService.getUserDetails().roleId ==2 ||this.userService.getUserDetails().roleId ==3 ;
      this.adminService.getAvailableReportData().subscribe(answer =>{
                    this.organizations = answer['organizations'];
                    this.tagList = answer['tags'];
                    this.tagList.sort((a,b)=>a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                    this.organizations.sort((a,b)=>a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                    for (let orgIndex in this.organizations){
                      if (this.organizations[orgIndex].id === this.userService.getUserDetails().organizationId){
                        this.selectedOrganizations=[this.organizations[orgIndex]];
                      }
                    }
                    this.filterTagList();
        });
    }

    onSelectAllOrg(){
      this.selectedOrganizations = [...this.organizations];
      this.filterTagList();
    }

    resetReport(){
      this.currentReport = null;
    }

    filterTagList(){
      if (!this.canSeeMultipleOrganisations)
        this.filteredTagList= this.tagList;
      else{
        let orgIdList = [];
        for (let i in this.selectedOrganizations)
        {
          orgIdList.push(this.selectedOrganizations[i].id)
        }
        this.filteredTagList=this.tagList.filter( tag=> orgIdList.includes(tag.orgId));
        this.reportTags=this.reportTags.filter( tag=> orgIdList.includes(tag.orgId) );
      }
    }

    generateReport(){
      let finalStartDate:Date = null;
      let finalStopDate:Date = null;
      if(!this.noDate)
      {
            finalStartDate = new Date(this.startDateValue);
            finalStartDate.setHours(0);
            finalStartDate.setMinutes(0);
            finalStartDate.setSeconds(0);

            finalStopDate = new Date(this.stopDateValue);
            finalStopDate.setHours(23);
            finalStopDate.setMinutes(59);
            finalStopDate.setSeconds(59);
      }
      let orgIdList:Number[] = [];
      for (let index in this.selectedOrganizations){
          orgIdList.push(this.selectedOrganizations[index].id);
      }
      this.adminService.generateReport(this.reportType,finalStartDate,finalStopDate,this.reportTags,orgIdList).subscribe(
       answer=>{
        this.currentReport = answer;
        setTimeout(()=>{
                 this.createCharts();
        },1);
       }
       );
    }
    generateExport(){
      let finalStartDate:Date = null;
      let finalStopDate:Date = null;
      if(!this.noDate)
      {
            finalStartDate = new Date(this.startDateValue);
            finalStartDate.setHours(0);
            finalStartDate.setMinutes(0);
            finalStartDate.setSeconds(0);

            finalStopDate = new Date(this.stopDateValue);
            finalStopDate.setHours(23);
            finalStopDate.setMinutes(59);
            finalStopDate.setSeconds(59);
      }
      let orgIdList:Number[] = [];
      for (let index in this.selectedOrganizations){
          orgIdList.push(this.selectedOrganizations[index].id);
      }
      this.adminService.generateExport(finalStartDate,finalStopDate,this.reportTags,orgIdList).subscribe(
       answer=>{
        this.onExportClicked(answer);
       }
       );
    }


    createCharts(){
          if(this.currentReport['reportType']==2){
            let evolutionPillarNumberData  =[
                {'name':'Début : 1 pilier','value':this.currentReport['evolutionReport']['pillarPresentCountT1'][0]*100,'color':'#B3DEE5'},
                {'name':'Fin : 1 pilier','value':this.currentReport['evolutionReport']['pillarPresentCountT2'][0]*100,'color':'#E5B9B3'},
                {'name':'Début : 2 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT1'][1]*100,'color':'#79C5D2'},
                {'name':'Fin : 2 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT2'][1]*100,'color':'#D28479'},
                {'name':'Début : 3 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT1'][2]*100,'color':'#40ACBF'},
                {'name':'Fin : 3 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT2'][2]*100,'color':'#BF4F40'},
                {'name':'Début : 4 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT1'][3]*100,'color':'#2D7986'},
                {'name':'Fin : 4 piliers','value':this.currentReport['evolutionReport']['pillarPresentCountT2'][3]*100,'color':'#86372D'},
            ];

          let evolutionPillarPresenceData  =[

              {'name':'Début : Securité','value':this.currentReport['evolutionReport']['pillars']['SECURITY'].pillarProportionT1*100,'color':'#81b3a3'},
              {'name':'Fin : Securité','value':this.currentReport['evolutionReport']['pillars']['SECURITY'].pillarProportionT2*100,'color':'#81b3a3'},
              {'name':'Début : Relations','value':this.currentReport['evolutionReport']['pillars']['RELATION'].pillarProportionT1*100,'color':'#f8cf63'},
              {'name':'Fin : Relations','value':this.currentReport['evolutionReport']['pillars']['RELATION'].pillarProportionT2*100,'color':'#f8cf63'},
              {'name':'Début : Santé','value':this.currentReport['evolutionReport']['pillars']['HEALTH'].pillarProportionT1*100,'color':'#e4526d'},
              {'name':'Fin : Santé','value':this.currentReport['evolutionReport']['pillars']['HEALTH'].pillarProportionT2*100,'color':'#e4526d'},
              {'name':'Début : Loisirs','value':this.currentReport['evolutionReport']['pillars']['HOBBIES'].pillarProportionT1*100,'color':'#c8d538'},
              {'name':'Fin : Loisirs','value':this.currentReport['evolutionReport']['pillars']['HOBBIES'].pillarProportionT2*100,'color':'#c8d538'},
          ];

          let evolutionPillarPartsData  =[

              {'name':'Début : Securité','value':this.currentReport['evolutionReport']['pillars']['SECURITY'].averagePartT1,'color':'#81b3a3'},
              {'name':'Fin : Securité','value':this.currentReport['evolutionReport']['pillars']['SECURITY'].averagePartT2,'color':'#81b3a3'},
              {'name':'Début : Relations','value':this.currentReport['evolutionReport']['pillars']['RELATION'].averagePartT1,'color':'#f8cf63'},
              {'name':'Fin : Relations','value':this.currentReport['evolutionReport']['pillars']['RELATION'].averagePartT2,'color':'#f8cf63'},
              {'name':'Début : Santé','value':this.currentReport['evolutionReport']['pillars']['HEALTH'].averagePartT1,'color':'#e4526d'},
              {'name':'Fin : Santé','value':this.currentReport['evolutionReport']['pillars']['HEALTH'].averagePartT2,'color':'#e4526d'},
              {'name':'Début : Loisirs','value':this.currentReport['evolutionReport']['pillars']['HOBBIES'].averagePartT1,'color':'#c8d538'},
              {'name':'Fin : Loisirs','value':this.currentReport['evolutionReport']['pillars']['HOBBIES'].averagePartT2,'color':'#c8d538'},
          ];

            this.createD3BarChart("evolution-pillar-count",evolutionPillarNumberData,"Evolution du nombre de piliers présents par passation",true);
            this.createD3BarChart("evolution-pillar-presence",evolutionPillarPresenceData,"Evolution du taux de présence de chaque pilier",true);
            this.createD3BarChart("evolution-pillar-parts",evolutionPillarPartsData,"Evolution du poids des différents piliers",true);
          }
          else{
              let pillarProportionData = [
                this.currentReport['passationReport']['pillarStatistics']['SECURITY'].averagePart,
                this.currentReport['passationReport']['pillarStatistics']['HEALTH'].averagePart,
                this.currentReport['passationReport']['pillarStatistics']['RELATION'].averagePart,
                this.currentReport['passationReport']['pillarStatistics']['HOBBIES'].averagePart,
              ];
              let pillarProportionNames=[
                      "Sécurité et cadre de vie",
                      "Santé et pratiques de santé",
                      "Vie affective et relation aux autres",
                      "Ressources personnelles et loisirs"
              ];
              let proportionColors = ["#81b3a3","#e4526d" ,"#f8cf63","#c8d538", "#eaeaea"];
              let proportionTitleText = "Part moyenne des différents piliers";
              let proportionDivClass = "pillar-proportion";
              this.createD3PieCharts(proportionDivClass,pillarProportionData,pillarProportionNames,proportionColors,proportionTitleText);
              let pillarCountData = [
                this.currentReport['passationReport']['pillarPresentCount'][0]*100,
                this.currentReport['passationReport']['pillarPresentCount'][1]*100,
                this.currentReport['passationReport']['pillarPresentCount'][2]*100,
                this.currentReport['passationReport']['pillarPresentCount'][3]*100,

              ];
              let pillarCountNames=[
                      "1 pilier",
                      "2 piliers",
                      "3 piliers",
                      "4 piliers"
              ];
                let pillarCountColors = ["#B3DEE5","#79C5D2" ,"#40ACBF","#2D7986"];
                let pillarCountTitleText = "Nombre de piliers cités (%)";
                let pillarCountDivClass = "passation-pillar-count";


                let data =[
                {'name':'Sécurité et cadre de vie','value':this.currentReport['passationReport']['pillarStatistics']['SECURITY'].pillarProportion*100,'color':'#81b3a3'},
                {'name':'Vie affective et relation aux autres','value':this.currentReport['passationReport']['pillarStatistics']['RELATION'].pillarProportion*100,'color':'#f8cf63'},
                {'name':'Santé pratiques de santé','value':this.currentReport['passationReport']['pillarStatistics']['HEALTH'].pillarProportion*100,'color': '#e4526d'},
                {'name':'Ressources personnelles et loisirs','value':this.currentReport['passationReport']['pillarStatistics']['HOBBIES'].pillarProportion*100,'color':'#c8d538'}
                ]

                this.createD3PieCharts(pillarCountDivClass,pillarCountData,pillarCountNames,pillarCountColors,pillarCountTitleText);
                this.createD3BarChart("passation-pillar-presence",data,"Taux de présence moyen des différents piliers dans les passations",true);
            }
    }

    createD3PieCharts(divClass,datas,names,colors,titleText){

            let width = 500;//element.getBoundingClientRect().width;
            let height = 300;//element.getBoundingClientRect().height;
            let titleMargin =50;
            let graphDimension = Math.min( height-titleMargin,width);
            let radius = graphDimension/2*0.9;
            let svg = d3.select("."+divClass)
              .append("svg")
              .attr("preserveAspectRatio", "xMinYMin meet")
              .attr("viewBox", "0 0 "+width+" "+height)
              .classed("svg-content", true)
              .attr("id",divClass+"-graph");

            let pieGen = d3.pie<Number>().sort(null);
            let chart = svg.append("g").attr("transform", "translate("+ graphDimension/2+","+ (titleMargin+graphDimension/2) +")");
            var arcGen  = d3.arc()
                          .innerRadius(0)
                          .outerRadius(radius);
            let arcsData = pieGen(datas);
            let arcLabel = <any>d3.arc().innerRadius(0).outerRadius(radius);

            let arcs = chart.attr("stroke", "white")
            .selectAll("path")
            .data(arcsData)
                  .join("path")
                  .attr("fill", (d,i) => colors[i])
                  .attr("d", <any>arcGen)
             ;

             let piePercents= svg.append("g").attr("transform", "translate("+ graphDimension/2+","+ (titleMargin+graphDimension/2) +")")
             .attr("font-family", "sans-serif")
            // .attr("font-size", "large")
             .attr("text-anchor", "middle")
             .selectAll("text").data(arcsData)
              .join("text")
              .text((d,i)=> datas[i]>15?datas[i].toFixed(1)+"%":"")
              .attr("transform",(d)=>   'translate('+arcLabel.centroid(d)+')')
              ;

              let title = svg.append('text').text(titleText)
                                            .attr('x', width/4 )
                                            .attr('y',titleMargin/3)
                                            .style('font-family', 'sans-serif')
                                            .style('font-size', `15px`)
                                            .style('text-decoration','underline');

              let legend = svg.append('g').attr('transform', 'translate('+graphDimension+','+(titleMargin+30)+')');
              for (let i=0;i<4;i++){
                legend.append('rect')
                      .attr('y', d => 20 * i * 1.8)
                      .attr('width', 12)
                      .attr('height', 10)
                      .attr('fill', colors[i])
                      .attr('stroke', 'grey')
                      .style('stroke-width', '1px');
                legend.append('text')
                                   .text(d => names[i] +" : "+datas[i].toFixed(1)+"%")
                                   .attr('x', 20)
                                   .attr('y', d => 20 * i * 1.8+10)
                                   .style('font-family', 'sans-serif')
                                   .style('font-size', `10px`);
              }


      }

    createD3BarChart(anchor, data,title,isPercent=false){
        let margin = {top: 50, right: 50, bottom: 75, left: 50},
            width = 750 - margin.left - margin.right,
            height = 450 - margin.top - margin.bottom;
        let x = d3.scaleBand()
                  .range([0, width])
                  .padding(0.1);
        let y = d3.scaleLinear()
                  .range([height, 0]);


        let svgBox = d3.select("."+anchor).append("svg")
                                 .attr("preserveAspectRatio", "xMinYMin meet")
                                 .attr("id",anchor+"-graph")
                                 .attr("viewBox", "0 0 "+(width + margin.left + margin.right)+" "+(height + margin.top + margin.bottom))
                                 .classed("svg-content", true);
        let svg = svgBox
          //  .attr("width", width + margin.left + margin.right)
          //  .attr("height", height + margin.top + margin.bottom)
          .append("g")
            .attr("transform",
                  "translate(" + margin.left + "," + margin.top + ")")
        x.domain(data.map(function(d) { return d['name']; }));
        y.domain([0, 100]);

       // Append rectangles for bar chart
        svg.selectAll(".bar")
            .data(data)
          .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function(d) { return x(d['name']); })
            .attr("width", x.bandwidth())
            .attr("y", function(d) { return y(d['value']); })
            .attr("fill", function(d) { return d['color']; })
            .attr("height", function(d) { return height - y(d['value']); });

           // Add x axis
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x));

            // Add y axis
            svg.append("g")
                .call(d3.axisLeft(y));

          svgBox.append('text').text(title)
                            .attr('x', width/4 )
                            .attr('y',margin.top/3)
                            .style('font-family', 'sans-serif')
                            .style('font-size', `17px`)
                            .style('text-decoration','underline');

          svg.selectAll("text.bar")
            .data(data)
          .enter().append("text")
            .attr("class", "bar")
            .attr("text-anchor", "middle")
            .attr("x", function(d) { return x(d['name'])+x.bandwidth()/2; })
            .attr("y", function(d) { return y(d['value']) - 10; })
            .text(function(d) { return d['value'].toFixed(1)+(isPercent?"%":""); });
      }

    /**
      export generated graph as png using saveSvgAsPng
    */
    saveGraph(id):void{
          saveSvgAsPng(document.getElementById(id), "diagram.png",{backgroundColor :'white'});
    }

    onPrintClicked():void{
      window.print();
    }

    /**
    Export as an xlsx the table figuring in Passation Reporting
    */
    onExportPassationTable():void{
      let data = [];
    data.push (["","Sécurité et cadre de vie",
    "Vie affective et relation aux autres",
    "Santé et autonomie physique et mentale",
    " Ressources personnelles et loisirs",]);

    data.push(["Cité par",
    (this.currentReport['passationReport']['pillarStatistics']['SECURITY']['pillarProportion']*100).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['RELATION']['pillarProportion']*100).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HEALTH']['pillarProportion']*100).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['pillarProportion']*100).toFixed(1)+ "%",
    ]);

    data.push(["Part moyenne",
    (this.currentReport['passationReport']['pillarStatistics']['SECURITY']['averagePart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['RELATION']['averagePart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HEALTH']['averagePart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['averagePart']).toFixed(1)+ "%",
    ]);

    data.push(["Part min/max",
    (this.currentReport['passationReport']['pillarStatistics']['SECURITY']['minimumPart']).toFixed(1)+ "%"+"-"+(this.currentReport['passationReport']['pillarStatistics']['SECURITY']['maximumPart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['RELATION']['minimumPart']).toFixed(1)+ "%"+"-"+(this.currentReport['passationReport']['pillarStatistics']['RELATION']['maximumPart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HEALTH']['minimumPart']).toFixed(1)+ "%"+"-"+(this.currentReport['passationReport']['pillarStatistics']['HEALTH']['maximumPart']).toFixed(1)+ "%",
    (this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['minimumPart']).toFixed(1)+ "%"+"-"+(this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['maximumPart']).toFixed(1)+ "%",
    ]);

    data.push(["Note moyenne",
    (this.currentReport['passationReport']['pillarStatistics']['SECURITY']['averageNote']).toFixed(1),
    (this.currentReport['passationReport']['pillarStatistics']['RELATION']['averageNote']).toFixed(1),
    (this.currentReport['passationReport']['pillarStatistics']['HEALTH']['averageNote']).toFixed(1),
    (this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['averageNote']).toFixed(1),
    ]);

    data.push(["Note min/max",
    this.currentReport['passationReport']['pillarStatistics']['SECURITY']['minimumNote']+"-"+this.currentReport['passationReport']['pillarStatistics']['SECURITY']['maximumNote'],
    this.currentReport['passationReport']['pillarStatistics']['RELATION']['minimumNote']+"-"+this.currentReport['passationReport']['pillarStatistics']['RELATION']['maximumNote'],
    this.currentReport['passationReport']['pillarStatistics']['HEALTH']['minimumNote']+"-"+this.currentReport['passationReport']['pillarStatistics']['HEALTH']['maximumNote'],
    this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['minimumNote']+"-"+this.currentReport['passationReport']['pillarStatistics']['HOBBIES']['maximumNote'],
    ]);

      let workbook = XLSX.utils.book_new();

      let worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

     let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      var bb = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      var a = document.createElement('a');
      a.download = 'exportTable.xlsx';
      a.href = window.URL.createObjectURL(bb);
      a.click();
  }
    /**
    Export as an xlsx the table figuring in Evolution Reporting
    */
    onExportEvolutionTable():void{

        let data = [];
        data.push (["Pillier","Sécurité et cadre de vie",
        "Vie affective et relation aux autres",
        "Santé et autonomie physique et mentale",
        " Ressources personnelles et loisirs",]);

        data.push(["T1 :Cité par",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['pillarProportionT1']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['RELATION']['pillarProportionT1']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['pillarProportionT1']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['pillarProportionT1']*100).toFixed(1)+ "%",
        ]);

        data.push(["T2 :Cité par",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['pillarProportionT2']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['RELATION']['pillarProportionT2']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['pillarProportionT2']*100).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['pillarProportionT2']*100).toFixed(1)+ "%",
        ]);

        data.push(["Différentiel",
        ((this.currentReport['evolutionReport']['pillars']['SECURITY']['pillarProportionT2']-this.currentReport['evolutionReport']['pillars']['SECURITY']['pillarProportionT1'])*100).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['RELATION']['pillarProportionT2']-this.currentReport['evolutionReport']['pillars']['RELATION']['pillarProportionT1'])*100).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['HEALTH']['pillarProportionT2']-this.currentReport['evolutionReport']['pillars']['HEALTH']['pillarProportionT1'])*100).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['HOBBIES']['pillarProportionT2']-this.currentReport['evolutionReport']['pillars']['HOBBIES']['pillarProportionT1'])*100).toFixed(1)+ "%",
        ]);

        data.push(["T1 : part moyenne",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['averagePartT1']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['RELATION']['averagePartT1']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['averagePartT1']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['averagePartT1']).toFixed(1)+ "%",
        ]);

        data.push(["T2 : part moyenne",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['averagePartT2']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['RELATION']['averagePartT2']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['averagePartT2']).toFixed(1)+ "%",
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['averagePartT2']).toFixed(1)+ "%",
        ]);

        data.push(["Différentiel",
        ((this.currentReport['evolutionReport']['pillars']['SECURITY']['averagePartT2']-this.currentReport['evolutionReport']['pillars']['SECURITY']['averagePartT1'])).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['RELATION']['averagePartT2']-this.currentReport['evolutionReport']['pillars']['RELATION']['averagePartT1'])).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['HEALTH']['averagePartT2']-this.currentReport['evolutionReport']['pillars']['HEALTH']['averagePartT1'])).toFixed(1)+ "%",
        ((this.currentReport['evolutionReport']['pillars']['HOBBIES']['averagePartT2']-this.currentReport['evolutionReport']['pillars']['HOBBIES']['averagePartT1'])).toFixed(1)+ "%",
        ]);

        data.push(["T1 : note moyenne",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['averageNoteT1']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['RELATION']['averageNoteT1']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['averageNoteT1']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['averageNoteT1']).toFixed(1),
        ]);

        data.push(["T2 : note moyenne",
        (this.currentReport['evolutionReport']['pillars']['SECURITY']['averageNoteT2']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['RELATION']['averageNoteT2']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['HEALTH']['averageNoteT2']).toFixed(1),
        (this.currentReport['evolutionReport']['pillars']['HOBBIES']['averageNoteT2']).toFixed(1),
        ]);

        data.push(["Différentiel",
        ((this.currentReport['evolutionReport']['pillars']['SECURITY']['averageNoteT2']-this.currentReport['evolutionReport']['pillars']['SECURITY']['averageNoteT1'])).toFixed(1),
        ((this.currentReport['evolutionReport']['pillars']['RELATION']['averageNoteT2']-this.currentReport['evolutionReport']['pillars']['RELATION']['averageNoteT1'])).toFixed(1),
        ((this.currentReport['evolutionReport']['pillars']['HEALTH']['averageNoteT2']-this.currentReport['evolutionReport']['pillars']['HEALTH']['averageNoteT1'])).toFixed(1),
        ((this.currentReport['evolutionReport']['pillars']['HOBBIES']['averageNoteT2']-this.currentReport['evolutionReport']['pillars']['HOBBIES']['averageNoteT1'])).toFixed(1),
        ]);

        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

       let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        var bb = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        var a = document.createElement('a');
        a.download = 'exportTable.xlsx';
        a.href = window.URL.createObjectURL(bb);
        a.click();
  }
    /**
    Export as an xlsx the passations used to build the report
    */
    onExportClicked(report:any):void{

      let data = [];
      data.push (["id","Organisation","Passateur","date","Code patient","Genre","Année de naissance",
                           "Tags","Score total","Commentaire",
                           "Elément 1","Elément 1 Pilier","Elément 1 Poids","Elément 1 Note" ,
                           "Elément 2","Elément 2 Pilier","Elément 2 Poids","Elément 2 Note" ,
                          " Elément 3","Elément 3 Pilier","Elément 3 Poids","Elément 3 Note" ,
                           "Elément 4","Elément 4 Pilier","Elément 4 Poids","Elément 4 Note" ,
                           "Elément 5","Elément 5 Pilier","Elément 5 Poids","Elément 5 Note" ]);

      for (let p of report['passations']){
          let line = [];
          line.push(p['id']);
          line.push(p['organizationName']);
          line.push(p['passateurName']);
          line.push(new Date(p['date']).toLocaleDateString());
          line.push(p['code']);
          switch(p['gender']){
            case 0 :
               line.push("Femme");
              break;
            case 1 :
               line.push("Homme");
              break;
            default :
               line.push("Non Binaire");
             break;
          }
           line.push(p['naissance']);
           let tagText = "";
          for (let tag of p['tags']){
            tagText+=tag['name']+" "
          }
          line.push(tagText);
           line.push(p['totalScore']);
           line.push(p['commentaire']);
          for (let item  of p.items){
              line.push(item["name"]);
              line.push(this.pillars[ item["pillar"]]);
              line.push(item["weight"]);
              line.push(item["note"]);
          }
          data.push(line);
      }


      let workbook = XLSX.utils.book_new();

      let worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

     let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      var bb = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      var a = document.createElement('a');
      a.download = 'exportDatas.xlsx';
      a.href = window.URL.createObjectURL(bb);
      a.click();
    }
  }
