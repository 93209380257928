<app-menu class="row"></app-menu>


<div class="d-flex justify-content-center">
  <h2>
    Passations existantes pour {{orgName}}
  </h2>
</div>

<div  *ngIf="!userPassations || userPassations.length== 0" class="d-flex justify-content-center">
  <i>
    Aucune passation à afficher
  </i>
</div>
<div *ngIf="userPassations && userPassations.length>0">
  <div class="row">
    <div class ="col-8 offset-2 d-flex justify-content-center gap-2 ">
      <label for="passation_order">Trier par </label>
      <select [(ngModel)]="passationSortOption" id="passation_order">
        <option [ngValue]="0">Identifiant</option>
        <option [ngValue]="1">Date</option>
        <option [ngValue]="2">Passateur</option>
      </select>
      <label for="passation_search">Rechercher </label>
      <input [(ngModel)]="passationFilter" id="passation_search" type="text">
    </div>
  </div>
  <div class ="row">
    <div class="col-md-1"></div>
    <div class="col-md-10 d-flex justify-content-center">
      <table class="user-passations-table">
        <tr>
          <th class="table-visible-cell">
            Identifiant
          </th>
          <th class="table-visible-cell">
            Passateur
          </th>
          <th class="table-visible-cell">
            Date
          </th>
          <th style="min-width:15rem;" class="table-visible-cell">
            Tags
          </th>
          <th>

          </th>
        </tr>
        <tr  *ngFor ="let passation of userPassations |passationTextFilter:passationFilter| passationSortPipe :passationSortOption| paginate: { itemsPerPage: 10, currentPage: passationPage }">
          <td class="table-visible-cell">
            {{passation.code}}
          </td>
          <td class="table-visible-cell">
            {{passation.passateurName}}
          </td>
          <td class="table-visible-cell">
            {{passation.date| date:"dd/MM/yyyy"}}
          </td>
          <td class="table-visible-cell">
            {{formatTags(passation.tags)}}
          </td>
          <td class="table-visible-cell">
            <button  [routerLink]="['/passation-review', passation.id]" class="btn btn-secondary"> Afficher</button>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="d-flex justify-content-center">
    <pagination-controls (pageChange)="passationPage = $event"></pagination-controls>
  </div>

</div>
