
<!--
<div class="d-flex align-items-center header-wrapper hidePrint">
  <div class="col-1"></div>
  <div class="col-10 d-flex justify-content-between">
    <div><img src="../../assets/logo_atev.png" class="header-logo"/></div>
    <div class="d-flex  justify-content-center align-items-center">
      <button [disabled]="isCurrentState('/')" class="  btn btn-primary btn-deconnexion header-item" routerLink="/">
        Accueil
      </button>
      <button [disabled]="isCurrentState('/user-passations')" class="  btn btn-primary btn-deconnexion header-item"
              routerLink="/user-passations">Mes passations
      </button>
      <button *ngIf="userRole>0" [disabled]="isCurrentState('/admin')"
              class="  btn btn-primary btn-deconnexion header-item" routerLink="/admin">Admin
      </button>
      <button *ngIf="userRole>0" [disabled]="isCurrentState('/reporting')"
              class="  btn btn-primary btn-deconnexion header-item" routerLink="/reporting">Rapports
      </button>
      <button *ngIf="userRole==2" [disabled]="isCurrentState('/admin-edit')"
              class="  btn btn-primary btn-deconnexion header-item" routerLink="/admin-edit">SuperAdmin
      </button>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="header-item">{{username}}</div>
      <button (click)="logout()" class="  btn btn-primary btn-deconnexion header-item">se déconnecter</button>
    </div>
  </div>
  <div class="col-1"></div>
</div>-->
<nav class="navbar navbar-expand-md navbar-light  header-wrapper hidePrint">
  <img src="../../assets/logo_atev.png" class="header-logo "/>
  <button (click)="logout()" class="d-block d-md-none  btn btn-primary btn-deconnexion btn-deconnexion-collapsed header-item">Déconnexion</button>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li [ngClass]="{'active': isCurrentState('/')}" class="nav-item ">
        <a class="nav-link"  routerLink="/">Accueil </a>
      </li>
      <li [ngClass]="{'active': isCurrentState('/user-passations')}" class="nav-item">
        <a class="nav-link" routerLink="/user-passations">Passations</a>
      </li>
      <li [ngClass]="{'active': isCurrentState('/reporting')}" *ngIf="userRole>0" class="nav-item">
        <a class="nav-link" routerLink="/reporting">Rapports</a>
      </li>
      <li [ngClass]="{'active': isCurrentState('/admin')}" *ngIf="userRole==1||userRole==2" class="nav-item">
        <a class="nav-link" routerLink="/admin">Admin</a>
      </li>
      <li [ngClass]="{'active': isCurrentState('/admin-edit')}" *ngIf="userRole==2" class="nav-item">
        <a class="nav-link" routerLink="/admin-edit">SuperAdmin</a>
      </li>
    </ul>


  </div>
  <div class="d-none d-md-block header-item">{{username}}</div>
  <button (click)="logout()" class="d-none d-md-block  btn btn-primary btn-deconnexion header-item">Déconnexion</button>
</nav>
