import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import {UserService, Organization} from '../user.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  username :String;
  userRole :Number;
  constructor(private userService:UserService, private router:Router) { }

  ngOnInit(): void {
      this.username = this.userService.getUserDetails().name;
      this.userRole = this.userService.getUserDetails().roleId;
  }

  isCurrentState(stateUrl:string):boolean{
    return this.router.url== stateUrl;
  }

  logout(){
    this.userService.logout().subscribe();
  }
}

