import { Component, OnInit } from '@angular/core';
import {UserDetails,UserRequest,Organization } from '../user.service';
import {AdminService} from '../admin.service';
import {UserService} from '../user.service';
import {NgbModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import  {serverURL} from "../config";
import {Router} from '@angular/router';
import {Passation,Tag, Item} from '../passation';

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.css']
})
export class AdminEditComponent implements OnInit {

  constructor(private userService:UserService, private adminService:AdminService
  ,private router : Router ,private modalService: NgbModal) { }

   username :String;
   organizations?: Organization[];
   supervisableOrganisations?:Organization[];
   users?:UserDetails[];
   passations?:Passation[];
   tags?:Tag[];
   availableTags?:Tag[];

   currentOrganizationId = -1;
   openedModal ?:NgbModalRef;
   editedPassation?:Passation;

   newTagName?:String;
   editTagName?:String;
   editTagId?:Number;

   newTagError?:String;
   editTagError?:String;
   editPassationError?:String;
   createUserErrorText:String='';
   updateUserErrorText:String='';
   updatePwdErrorText:String='';
   newUserPasswordConfirm:String='';
   updatePasswordConfirm:String ='';

   supervisedOrgToAdd?:Organization;

   editedPassationTagToAdd?:Tag;
   notations:Number[] = [10,9,8,7,6,5,4,3,2,1,0];
   pillarNames:String[]=["Sécurité et cadre de vie","Santé et pratiques de santé","Vie affective et relation aux autres","Ressources personnelles et loisirs"];

   passationPage:Number=1;
   tagPage:Number=1;
   organisationPage:Number=1;
   userPage:Number=1;

   passationSortOption:Number=1;
   passationFilter:string="";
   newOrgErrorText:String ='';
   updateOrgErrorText:String='';
   editedOrgName?:String;
   newOrgName?:String;
   updateOrgId ?: Number;
   updateOrgName?:String;

    newUser ?:UserRequest;
    toUpdateUser ?:UserRequest;
    updatePwdUserId ?:Number;
    editedUserName?:String;

   userFilter:String ="";
   roleMap = this.userService.roleMap;


  ngOnInit(): void {
    this.adminService.getOrganizations().subscribe(answer =>{
      this.organizations = answer['organizations'];
      this.currentOrganizationId = answer['organizations'][0].id;
      this.refreshOrganizationItems();
    });
  }

  refreshOrganization(){
        this.adminService.getOrganizations().subscribe(answer =>{
          this.organizations = answer['organizations'];
        });
  }


 /* rebindTag(){
    this.adminService.rebindTag().subscribe(answer=>{});
  }*/

  refreshOrganizationItems(){
    this.adminService.getPassationsByOrganization(this.currentOrganizationId).subscribe(answer=>{
      this.passations=answer['passations'];
    })
    this.adminService.getUsersByOrganization(this.currentOrganizationId).subscribe(answer=>{
      this.users=answer['userDetailsView'];
    })
    this.adminService.getTagsByOrganization(this.currentOrganizationId).subscribe(answer=>{
      this.tags = answer['tags'];
      this.tags.sort(function(a, b){
                   if(a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                   if(a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
                   return 0;
               })
    });
  }


  logout(){
    this.userService.logout().subscribe();
  }

  modalClose(){
      this.openedModal.close();
  }

  tagFormat( tags:Tag[]){
    var nameArray = [];
    for (let tag of  tags){
      nameArray.push(tag.name);
    }
    return nameArray.join(',');
  }

  deletePassation(id){
    if(confirm("Voulez vous supprimer la passation d'identifiant "+id)){
      this.adminService.deletePassation(id).subscribe(answer=>{
          this.refreshOrganizationItems();
      })
    }
  }


  openEditPassation(modal,passation:Passation){
    this.openedModal = this.modalService.open(modal);

//    this.editedPassation = this.duplicatePassation(passation);
    this.editedPassation = JSON.parse(JSON.stringify(passation));
    this.computeAvailableTag();

  }

  computeAvailableTag(){
    this.availableTags = [];
    for( let existingOrgTag of this.tags){
      let isPresent:Boolean = false;
      for (let presentTag of this.editedPassation.tags){
        if (presentTag.id == existingOrgTag.id){
          isPresent = true;
        }
      }
      if (!isPresent){
        this.availableTags.push(existingOrgTag);
      }
    }
    if (this.availableTags.length > 0){
        this.editedPassationTagToAdd = this.availableTags[0];
    }
  }

  computeAvailableSupervizedOrg(currentOrgId){
    this.supervisableOrganisations =this.organizations.filter(org=> org.id != currentOrgId);
  }

  resetSupervizedOrganization(newOrgId){
     this.computeAvailableSupervizedOrg(newOrgId);
     this.toUpdateUser.supervisedOrganizations = this.toUpdateUser.supervisedOrganizations.filter(org=>org.id != newOrgId);
  }

  editPassationAddTag(tag:Tag){
    this.editedPassation.tags.push(tag);
    this.computeAvailableTag();
  }

  editPassationRemoveTag(index:number){
    this.editedPassation.tags.splice(index,1);
    this.computeAvailableTag();
  }

  editPassationAddItem(){
  let item = new Item();
  item.weight = 0;
  item.pillar =0;
  item.note = 1;
    this.editedPassation.items.push(item);
  }

  editPassationRemoveItem(id){
    if(confirm("Voulez vous vraiment supprimer cet élément ?"))
      this.editedPassation.items.splice(id,1);
  }

  computeSum(){
    let sum =0;
    for (let item of this.editedPassation.items){
        sum+=item.weight;
     }
     return sum;
  }

  isMissingItemName(){
   let isMissingName = false;
       for (let item of this.editedPassation.items){
           isMissingName = isMissingName|| item.name =="";
        }
   return isMissingName;
  }


  openNewTagModal(modal){
    this.openedModal = this.modalService.open(modal);
    this.newTagError ="";
    this.newTagName="";
  }

  submitNewTag(){
    this.adminService.createTag(this.newTagName,this.currentOrganizationId).subscribe(answer=>{
       this.openedModal.close();
       this.refreshOrganizationItems();
   },
     error=>{
       this.newTagError = error;
     });
  }

    openEditTagModal(modal,tag){
      this.openedModal = this.modalService.open(modal);
      this.editTagError ="";
      this.editTagId = tag.id;
      this.editTagName=tag.name;
    }

    submitEditTag(){
      this.adminService.updateTag(this.editTagName,this.editTagId,this.currentOrganizationId).subscribe(answer=>{
         this.openedModal.close();
         this.refreshOrganizationItems();
     },
       error=>{
         this.editTagError = error;
       });
    }

  deleteTag(tagId,tagName){
    if(confirm("Confirmez la suppression du tag "+tagName)){
      this.adminService.deleteTag(tagId).subscribe(answer=>{
        this.refreshOrganizationItems();
      });
    }
  }

    //compute new score & send form
   editPassation(){
       this.editedPassation.totalScore = 0;
       this.editedPassation.items.forEach(item=>{
             if(item.name !== ''){
               item.score = item.weight*item.note / 10/*0*/;
               this.editedPassation.totalScore += + item.score;
             }
      });
      this.adminService.updatePassation(this.editedPassation).subscribe(answer=>{
          this.openedModal.close();
          this.refreshOrganizationItems();
          this.refreshOrganization();
      },
        error=>{
          this.editPassationError = error;
        });
   }

   //open org create form
  openCreateOrgModal(newOrganizationModal){
      this.newOrgErrorText = '';
      this.newOrgName ="";
      this.openedModal= this.modalService.open(newOrganizationModal);
  }

// send org create form to server
  createOrganization(name:String){
    this.adminService.createOrganization(name).subscribe(answer=>
    {
      this.refreshOrganizationItems();
      this.refreshOrganization();
      this.openedModal.close();
    },
    error=>{
      this.newOrgErrorText = error;
    });
  }

  // open org update form
    openUpdateOrgModal(updateOrganizationModal,organization:Organization){
        this.editedOrgName = organization.name;
        this.updateOrgName = organization.name;
        this.updateOrgId = organization.id;
        this.updateOrgErrorText = "";
        this.openedModal= this.modalService.open(updateOrganizationModal);
    }

  // send Update org form to server
    updateOrganization(id:Number, name:String){
      this.adminService.updateOrganization(id,name).subscribe(answer=>
      {
        this.refreshOrganizationItems();
        this.refreshOrganization();
        this.openedModal.close();
      },
      error=>{
        this.updateOrgErrorText = error;
      });
    }

 // set Org disabled
    disableOrganization(organization:Organization):void{
      if(confirm("confirmez la désactivation de "+organization.name)){
        this.adminService.setEnabledOrganization(organization.id,false).subscribe(answer=>{
        this.refreshOrganization();
        this.refreshOrganizationItems();
        },
         error =>alert(error))
      }
    }

    // set Org enabled
    enableOrganization(organization:Organization):void{
      if(confirm("confirmez l'activation de "+organization.name)){
        this.adminService.setEnabledOrganization(organization.id,true).subscribe(answer=>{
        this.refreshOrganization();
        this.refreshOrganizationItems();
        },
         error =>alert(error))
      }
    }

  // Open New User Modal
  openCreateUserModal(newUserModal){
      this.openedModal= this.modalService.open(newUserModal,{ size: <any>'lg' });
      this.newUser = new UserRequest();
      // default value
      this.newUser.organizationId = this.currentOrganizationId;
      this.computeAvailableSupervizedOrg(this.currentOrganizationId)
      this.newUser.roleId = 0;
      this.newUser.supervisedOrganizations = [];
      this.supervisedOrgToAdd = null;
      this.createUserErrorText = "";
      this.newUserPasswordConfirm = "";
  }

  openUpdateUserModal(updateUserModal,userToUpdate:UserDetails){
      this.editedUserName =userToUpdate.name;
      this.openedModal= this.modalService.open(updateUserModal,{ size: <any>'lg' });
      this.toUpdateUser = new UserRequest();
      this.toUpdateUser.id = userToUpdate.id;
      this.toUpdateUser.name = userToUpdate.name;
      this.toUpdateUser.email = userToUpdate.email;
      this.toUpdateUser.roleId = userToUpdate.roleId;
      this.toUpdateUser.organizationId = userToUpdate.organizationId;
      this.computeAvailableSupervizedOrg(userToUpdate.organizationId);
      this.toUpdateUser.supervisedOrganizations = [...userToUpdate.supervisedOrganizations];
      this.supervisedOrgToAdd = null;
      this.updateUserErrorText = '';
  }

  openUpdatePasswordUserModal(updatePasswordUserModal,user:UserDetails){
      this.openedModal= this.modalService.open(updatePasswordUserModal);
      this.updatePwdUserId = user.id;
      this.editedUserName = user.name;
      this.updatePwdErrorText ="";
      this.updatePasswordConfirm = "";
  }
  updateUserPassword(userId:Number,pwd:String){
        this.adminService.updateUserPassword(userId,pwd).subscribe (answer => {
           this.openedModal.close();
        },
        error=>{
          this.updatePwdErrorText = error;
        });
      }

      createUser(newUser:UserRequest){
        this.adminService.createUser(newUser).subscribe(answer=>{
          this.refreshOrganizationItems();
          this.openedModal.close();
        },
        error=>{
          this.createUserErrorText = error;
        })
      }

      updateUser(updateUser:UserRequest){
        this.adminService.updateUser(updateUser).subscribe(answer=>{
          this.refreshOrganizationItems();
          this.openedModal.close();
        },
        error=>{
          this.updateUserErrorText = error;
        })
      }
      disableUser(userId:Number, userName:String){
        if (confirm("Confirmez la désactvation de l'utilisateur : "+ userName))
        {
          this.adminService.setEnabledUser(userId,false).subscribe(answer=>{
            this.refreshOrganizationItems();
          },
          error =>alert(error));
        }
      }
      enableUser(userId:Number, userName:String){
        if (confirm("Confirmez l'activation de l'utilisateur : "+ userName))
        {
          this.adminService.setEnabledUser(userId,true).subscribe(answer=>{
            this.refreshOrganizationItems();
          },
           error =>alert(error));
        }
      }

}

