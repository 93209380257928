
import {formatDate} from '@angular/common';

export class Passation{
  id :-1;
  code : string ="";
  gender : GenderEnum;
  naissance : number = 1990;
  date : Date = new Date();
  codePostal : string ="86000";
  tags : Tag[] = [];
  items : Item[] = [];
  totalScore : number;
  comment : string;
  organizationName:String;
  passateurName:String;
  isTest:boolean =false;


}

export class Item{
  constructor(){
    this.name = "";
    this.note = -1;
    this.weight = -1;
    this.pillar = null;
    this.score =0;
  }

  name : string;
  note : number;
  weight : number;
  pillar : Pillar;
  score : number;
}

export enum Pillar{
  "Sécurité et cadre de vie" =0,
  "Santé et Pratiques de santé" ,
  "Vie affective et relation aux autres" ,
  "Ressources personnelles et loisirs"
}

export enum GenderEnum{
  Femme=0,
  Homme =1,
  NSP=2,
}

export class Tag{
  constructor(id:number,name:string){
    this.id = id;
    this.name = name;
  }

  id : number;
  name:string;
  orgId:number;
}
