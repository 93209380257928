import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from './user.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       let userDetails = this.userService.getUserDetails();
        if (userDetails== null){
           return this.router.parseUrl('/login');
        }
        else if (userDetails.roleId !=2)
          return this.router.parseUrl('/');
        else
          return true;
  }
  constructor( private router: Router,private userService : UserService){}

}
