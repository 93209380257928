import { Component, OnInit } from '@angular/core';
import {UserDetails,UserRequest, } from '../user.service';
import {AdminService} from '../admin.service';
import {UserService, Organization} from '../user.service';
import {NgbModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import  {serverURL} from "../config";
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private userService:UserService, private adminService:AdminService
  ,private router : Router ,private modalService: NgbModal) { }

  newOrgName?:String;
  username :String;
  userRole :Number;
  users ?: UserDetails[];
  organizations?: Organization[];
  selectedOrganizations:boolean[];
  updateOrgId ?: Number;
  updateOrgName?:String;
  openedModal ?:NgbModalRef;
  newUser ?:UserRequest;
  toUpdateUser ?:UserRequest;
  updatePwdUserId ?:Number;
  exportUrl :string;
  exportOrgUrl :string;
  roleMap ?:String[];
  isSuperAdmin :Boolean;
  editedUserName?:String;
  editedOrgName?:String;
  deletePassationId ?:Number;

  newOrgErrorText:String ='';
  updateOrgErrorText:String='';
  createUserErrorText:String='';
  updateUserErrorText:String='';
  updatePwdErrorText:String='';
  newUserPasswordConfirm:String='';
  updatePasswordConfirm:String ='';

  exportOrganisationId:Number=-1;
  exportDateType:string="exportAll";
  exportDate:Date= new Date;

  userFilter:String ="";
  deletePassationErrorText:String ="";

  userPagination:Number=1;

  ngOnInit(): void {
    this.selectedOrganizations = [];
    this.exportUrl = serverURL+"/api/superAdmin/export";
    this.exportOrgUrl = serverURL+"/api/admin/export";
    this.username = this.userService.getUserDetails().name;
    this.userRole = this.userService.getUserDetails().roleId;
    this.roleMap = this.userService.roleMap;
    this.isSuperAdmin = this.userService.getUserDetails().roleId == 2;
    this.requestDatas();
  }

  requestDatas(){
    this.adminService.getUsers().subscribe(answer =>{
      this.users = answer['userDetailsView'];
    });
    if (this.isSuperAdmin){
          this.adminService.getOrganizations().subscribe(answer =>{
          this.organizations = answer['organizations'];
        });
    }
  }

  logout(){
    this.userService.logout().subscribe();
  }

  modalClose(){
      this.openedModal.close();
  }

  openCreateOrgModal(newOrganizationModal){
      this.newOrgErrorText = '';
      this.newOrgName ="";
      this.openedModal= this.modalService.open(newOrganizationModal);
  }

  createOrganization(name:String){
    this.adminService.createOrganization(name).subscribe(answer=>
    {
      this.requestDatas();
      this.openedModal.close();
    },
    error=>{
      this.newOrgErrorText = error;
    });
  }

    openCreateUserModal(newUserModal){
        this.openedModal= this.modalService.open(newUserModal);
        this.newUser = new UserRequest();
        this.newUser.organizationId = this.userService.getUserDetails().organizationId;
        this.newUser.roleId = 0;
        this.createUserErrorText = "";
        this.newUserPasswordConfirm = "";
    }

    openUpdateUserModal(updateUserModal,userToUpdate:UserDetails){
        this.editedUserName =userToUpdate.name;
        this.openedModal= this.modalService.open(updateUserModal);
        this.toUpdateUser = new UserRequest();
        this.toUpdateUser.id = userToUpdate.id;
        this.toUpdateUser.name = userToUpdate.name;
        this.toUpdateUser.email = userToUpdate.email;
        this.toUpdateUser.roleId = userToUpdate.roleId;
        this.toUpdateUser.organizationId = userToUpdate.organizationId;
        this.updateUserErrorText = '';
    }

    openUpdatePasswordUserModal(updatePasswordUserModal,user:UserDetails){
        this.openedModal= this.modalService.open(updatePasswordUserModal);
        this.updatePwdUserId = user.id;
        this.editedUserName = user.name;
        this.updatePwdErrorText ="";
        this.updatePasswordConfirm = "";
    }

    openExportModal(modal){
          this.openedModal= this.modalService.open(modal);
          if(this.isSuperAdmin==true){
            this.exportOrganisationId = -1;
          }
          let now  = new Date();
      }

    computeExportUrl(){
      let exportDateValue = new Date(this.exportDate);
      let res ="";
      if(this.isSuperAdmin==true){
        res = this.exportUrl+"/"+this.exportOrganisationId;
      }
      else{
        res = this.exportOrgUrl;
      }
      if (this.exportDateType == 'exportFromDate'){
      res += "/" +exportDateValue.getTime();
      }
      return res;
    }


    updateUserPassword(userId:Number,pwd:String){
      this.adminService.updateUserPassword(userId,pwd).subscribe (answer => {
         this.openedModal.close();
      },
      error=>{
        this.updatePwdErrorText = error;
      });
    }

    createUser(newUser:UserRequest){
      this.adminService.createUser(newUser).subscribe(answer=>{
        this.requestDatas();
        this.openedModal.close();
      },
      error=>{
        this.createUserErrorText = error;
      })
    }

    updateUser(updateUser:UserRequest){
      this.adminService.updateUser(updateUser).subscribe(answer=>{
        this.requestDatas();
        this.openedModal.close();
      },
      error=>{
        this.updateUserErrorText = error;
      })
    }

    disableUser(userId:Number, userName:String){
      if (confirm("Confirmez la désactvation de l'utilisateur : "+ userName))
      {
        this.adminService.setEnabledUser(userId,false).subscribe(answer=>{
          this.requestDatas();
        },
        error =>alert(error));
      }
    }

    enableUser(userId:Number, userName:String){
      if (confirm("Confirmez l'activation de l'utilisateur : "+ userName))
      {
        this.adminService.setEnabledUser(userId,true).subscribe(answer=>{
          this.requestDatas();
        },
         error =>alert(error));
      }
    }
}
