import { Pipe, PipeTransform } from '@angular/core';
import {Passation} from './passation';

@Pipe({
  name: 'passationUserTextFilter'
})
export class PassationUserTextFilterPipe implements PipeTransform {

  transform(passations: Passation[],filter:string):Passation[] {
        if(filter == "")
          return passations;

        else return passations.filter(passation=> passation.code.indexOf(filter)  !== -1
        || passation.date.toString().indexOf(filter) !==-1
        || passation.tags.filter(tag=>tag.name.indexOf(filter)!==-1 ).length>0

        );
  }


}

