import { Component, OnInit } from '@angular/core';
import { PassationService} from '../passation.service';
import { Passation,Tag,GenderEnum} from  '../passation';
import {Router} from '@angular/router';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class InformationComponent implements OnInit {

  passation ?: Passation;
  errorMessage : string ='';
  tags ?: Tag[];
  codes?: String[];
  GenderEnum = GenderEnum;
  hasTriedInvalidForm :boolean = false;
  doCodeExist :boolean = null;
  maxBirthDate:number ;

  constructor(private router : Router,private passationService : PassationService) { }

  ngOnInit(): void {
      this.maxBirthDate = new Date().getFullYear();
      this.passation = this.passationService.getPassation();
      if (this.passation.isTest)
      {
        this.tags=[];
      }else{
        this.passationService.getTags().subscribe(tagView => this.tags = tagView.tags);
        this.passationService.getCodes().subscribe(codes => this.codes = codes);
      }

  }

  savePassation():void{
    this.passationService.savePassation();
  }

  validateNextStep():boolean{

    return this.passation.code !== ""
        && this.passation.date !==  null
        && this.passation.codePostal !==""
        && this.passation.naissance !== null;
  }

  onCodeFocusOut(){
    this.passationService.doCodeExist(this.passation.code).subscribe(anwser=> this.doCodeExist = anwser);
  }
  onSubmit():void{
    if(this.validateNextStep()){
        this.router.navigateByUrl('/items');
    }
    else{
      this.hasTriedInvalidForm = true;
    }
  }
}
