import { Component, OnInit } from '@angular/core';
import {Passation, Pillar,Item,GenderEnum  } from '../passation';
import {PassationService} from '../passation.service';
import * as d3 from 'd3';
import * as Chartist from 'Chartist';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {UserService} from '../user.service';
import {GraphService} from '../graph.service';

declare var saveSvgAsPng: any;
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ResultsComponent implements OnInit {

  constructor(private userService:UserService,private router:Router
             ,private passationService : PassationService, private modalService: NgbModal
             ,private graphService:GraphService) { }

  private svg;
  private margin = 0;
  private width = 750;
  private height = 600;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;
  private colors;
  private activeModal;
  commentControlForm = new FormControl();


  pillarEnum = Pillar;
  passation:Passation;
  previousPassationList ?: Passation[];
  hasPreviousPassation?: boolean;
  previousPassation:Passation;
  previousPassationChoice ?:Passation = null;
  activeTab:number = -1;
  pillarScore : Map<Pillar,number>;
  isPillarPresent:boolean[] = [false,false,false,false];
  isPillarPresentPrevious:boolean[] = [false,false,false,false];
  pillarEvolution;
  test:String;
  username:String;
  organization:String;
  comparaisonCommentaire:String;
  sendError:String;

  ngOnInit(): void {
        this.passation = this.passationService.getPassation();
        this.passation.totalScore = 0;
        this.passation.items.forEach(item=>{
          if(item.name !== ''){
            item.score = item.weight*item.note / 10/*0*/;
            this.passation.totalScore += + item.score;
          }
        });
        //this.computePies(this.passation,"current-passation-pies");
       this.isPillarPresent= this.graphService.createD3PieCharts(this.passation,"current-passation-pies");
        this.passationService.getPreviousPassations().subscribe(anwser=> {
            this.previousPassationList = anwser.passations;
            this.hasPreviousPassation = this.previousPassationList.length > 0;
        });
  }



  public toGenderString(genderId:number){
    return GenderEnum[genderId];
  }


  onSendDone(){
    this.passationService.clearPassation();
    this.router.navigateByUrl('/');
  }

  openModal(modal):void{
    this.modalService.open(modal);
  }



  onValidatePassationChoice(modal,chosenPassation:Passation):void{
    modal.close();
    this.previousPassation = chosenPassation;
    setTimeout(()=>this.isPillarPresentPrevious= this.graphService.createD3PieCharts(this.previousPassation,"previous-passation-pies")
                           ,1);
    this.activeTab =1;
    this.computePillarEvolution();
  }

  onValidateSave(modal){
   this.passationService.sendPassation().subscribe(
    value=>{
      this.onSendDone();
      modal.close();
    },error=>{
      if (error == 0){
        this.sendError = "Le serveur ne répond pas.";
      }
      else{
        this.sendError ="Erreur : code "+ error;
      }
    }
   );
  }

  endTest(modal){
      modal.close();
      this.passationService.clearPassation();
      this.router.navigateByUrl('/');
  }

  onPrintClicked():void{
    window.print();
  }

  cancelPassationComparaison(){
    this.activeTab = -1;
    this.previousPassation = null;
  }


  computePillarEvolution(){
    this.pillarEvolution = [4];
    for (let i=0; i<4;i++){
      this.pillarEvolution[i] = [0,0];
    }
    this.passation.items.forEach(item=>{
        if(item.name != ""){
          this.pillarEvolution[item.pillar][1] += item.score;
        }
    });
    this.previousPassation.items.forEach(item=>{
        if(item.name != ""){
          this.pillarEvolution[item.pillar][0] += item.score;
        }
    });
  }

  testSave(){
    saveSvgAsPng(document.getElementById("current-passation-pies-test"), "diagram.png");
  }

}
