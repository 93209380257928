import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import {InformationComponent} from './information/information.component';
import {PassationComponent} from './passation/passation.component';
import {ItemsComponent} from './items/items.component';
import {ItemsNoteComponent} from './items-note/items-note.component'
import {ItemsWeightComponent} from './items-weight/items-weight.component'
import {ItemsPillarComponent } from './items-pillar/items-pillar.component';
import { ResultsComponent } from './results/results.component';
import {AdminComponent} from './admin/admin.component';
import {HomeComponent } from './home/home.component';
import {LoginComponent} from './login/login.component';
import {AdminEditComponent} from './admin-edit/admin-edit.component';
import {UserPassationsComponent} from './user-passations/user-passations.component';
import {PassationReviewComponent} from './passation-review/passation-review.component';
import {ReportingComponent} from './reporting/reporting.component';

import {PassationChildsGuard} from './passation-childs.guard';
import {AuthGuard} from './auth.guard';
import {AdminGuard} from './admin.guard';
import {SuperAdminGuard} from './super-admin.guard';
import {RapporteurGuard} from './rapporteur.guard';

const routes: Routes = [

 {
    path: 'admin', component: AdminComponent,
    canActivate: [AdminGuard],
  },
  {
    path:"admin-edit",
    component: AdminEditComponent,
    canActivate:[SuperAdminGuard],
  },
  {
    path:"reporting",
    component: ReportingComponent,
    canActivate:[RapporteurGuard],
  },
  {
    path:"user-passations",
    component: UserPassationsComponent,
  },
  {
    path:"passation-review/:passationId",
    component:PassationReviewComponent,
  },
  {
    path: '', component: PassationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PassationChildsGuard],
    children:[
      {
        path:'information',
        component: InformationComponent,
      },
      {
        path:'items',
        component: ItemsComponent,
      },
      {
        path:'items-weight',
        component: ItemsWeightComponent,
      },
      {
        path:'items-note',
        component: ItemsNoteComponent,
      },
      {
        path:'items-pillar',
        component: ItemsPillarComponent,
      },
      {
        path:'results',
        component: ResultsComponent,
      },
    ],
  },
  {
      path: 'login', component: LoginComponent,
  },
 { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRootingModule { }
